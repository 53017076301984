import * as React from 'react';
import { Product, Store } from "../../../../types";
import { useState, useEffect } from "react";
import { getAllProducts } from "../../../../lib/admin";
import { SpinnerCircularFixed } from '../../../../assets/spinner/SpinnerCircularFixed';
import StoreReportProductItem from './StoreReportProductItem';
import StoreReportProductHeader from './StoreReportProductHeader';
import StoreReportProductFilterMenu from './StoreReportProductFilterMenu';
import StoreReportPagination from '../StoreReportPagination';

type Props =
{
    store: Store
}

export default function StoreReportProductList({ store }: Props) {
    const [isLoading, setLoading] = useState(true);
    const [products, setProducts] = useState<undefined | Product[]>(undefined);
    const [filteredProducts, setFilteredProducts] = useState<undefined | Product[]>(undefined); 
    const [filters, setFilters] = useState({
        inMirror: false,
        inShopify: false,
        onIndex: false,
        notInMirror: false,
        notInShopify: false,
        notOnIndex: false
    });
    const [orderBy, setOrderBy] = useState<string>('default');
    const [clearFilter, setClearFilter] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [retrieveError, setRetrieveError] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 25;
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    
    const currentProducts = filteredProducts != undefined ? filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct) : products?.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalProducts = filteredProducts !== undefined ? filteredProducts.length : products?.length;
    const currentProductsCount = currentProducts?.length || 0;
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const searchProduct: () => void = () => {
        if (search.length >= 2 && products !== undefined && products?.length > 0) {
            if(!filters.inMirror || !filters.inShopify || !filters.onIndex || !filters.notInMirror || !filters.notInShopify || !filters.notOnIndex || orderBy !== 'default'){
                const filteredProducts = handleFilters();
                const productsFind = filteredProducts?.filter(p => p.productname?.toLocaleLowerCase().match(search.toLocaleLowerCase()));
                setFilteredProducts(productsFind ?? []);
            }else{
                const productsFind = products?.filter(p => p.productname?.toLocaleLowerCase().match(search.toLocaleLowerCase()));
                setFilteredProducts(productsFind ?? []);
            }
        }
    }

    useEffect(() => {
        if(search != "")
            searchProduct();
        else
            updateProducts();
    }, [search]);

    const handleFilters = () => {
        if(products != undefined){
            let productFiltered = [...products];
            if(productFiltered !== undefined){
                if (filters.inShopify)
                    productFiltered = productFiltered.filter(p => p.isInShopify);
                if (filters.inMirror)
                    productFiltered = productFiltered.filter(p => p.isInMirror);
                if (filters.onIndex)
                    productFiltered = productFiltered.filter(p => p.isInIndex);
                if (filters.notInShopify)
                    productFiltered = productFiltered.filter(p => !p.isInShopify);
                if(filters.notInMirror)
                    productFiltered = productFiltered.filter(p => !p.isInMirror);
                if (filters.notOnIndex)
                    productFiltered = productFiltered.filter(p => !p.isInIndex);
                if (orderBy === "name-asc")
                    productFiltered = productFiltered.sort((a, b) => a.productname.localeCompare(b.productname));
                else if (orderBy === "name-desc")
                    productFiltered = productFiltered.sort((a, b) =>  b.productname.localeCompare(a.productname));
            }
            return productFiltered;
        }
    };

    useEffect(() => {
        if(!clearFilter){
            if(!filters.inShopify && !filters.inMirror && !filters.onIndex 
                && !filters.notInMirror && !filters.notInShopify && !filters.notOnIndex && orderBy === 'default') {
                if(filteredProducts != undefined){
                    updateProducts();
                } 
            }else{
                if(search == ""){
                    const productsFiltered = handleFilters();
                    setFilteredProducts(productsFiltered);
                }
                else
                    searchProduct();
            }
        }
    }, [filters, orderBy]);

    useEffect(() => { 
        if(clearFilter){
            setFilteredProducts(undefined);
            setSearch("");
            setFilters({
                onIndex: false,
                inMirror: false,
                inShopify: false,
                notInMirror: false,
                notInShopify: false,
                notOnIndex: false
            });
            setClearFilter(false);
        }
    }, [clearFilter]);

    const updateProducts = async () => {
        setLoading(true);
        setFilteredProducts(undefined);
        setProducts([]);
        try{
            const newProducts = await getAllProducts(store.id);
            setProducts(newProducts);
            setLoading(false);
        }
        catch {
            setRetrieveError(true);
            setLoading(false);
        }
    }

    const updateSearch: (value: string) => void = (value: string) => {
        setSearch(value);
    }

    const updateShopify = (inShopify: boolean) => {
        if(inShopify)
            setFilters(prevFilters => ({
            ...prevFilters,
            inShopify: inShopify,
            notInShopify: !inShopify
            }));
        else
            setFilters(prevFilters => ({
                ...prevFilters,
                inShopify: inShopify
            }));  
    }

    const updateMirror = (inMirror: boolean) => {
        if(inMirror)
            setFilters(prevFilters => ({
            ...prevFilters,
            inMirror: inMirror,
            notInMirror: !inMirror
            }));
        else
            setFilters(prevFilters => ({
                ...prevFilters,
                inMirror: inMirror
            }));  
    }

    const updateIndex = (onIndex: boolean) => {
        if(onIndex)
            setFilters(prevFilters => ({
            ...prevFilters,
            onIndex: onIndex,
            notOnIndex: !onIndex
            }));
        else
            setFilters(prevFilters => ({
                ...prevFilters,
                onIndex: onIndex
            }));  
    }

    const updateNotShopify = (inShopify: boolean) => {
        if(inShopify)
            setFilters(prevFilters => ({
            ...prevFilters,
            notInShopify: inShopify,
            inShopify: !inShopify
            }));
        else
            setFilters(prevFilters => ({
                ...prevFilters,
                notInShopify: inShopify
            }));  
    }

    const updateNotMirror = (inMirror: boolean) => {
        if(inMirror)
            setFilters(prevFilters => ({
            ...prevFilters,
            notInMirror: inMirror,
            inMirror: !inMirror
            }));
        else
            setFilters(prevFilters => ({
                ...prevFilters,
                notInMirror: inMirror
            }));  
    }

    const updateNotIndex = (onIndex: boolean) => {
        if(onIndex)
            setFilters(prevFilters => ({
            ...prevFilters,
            notOnIndex: onIndex,
            onIndex: !onIndex
            }));
        else
            setFilters(prevFilters => ({
                ...prevFilters,
                notOnIndex: onIndex
            }));  
    }

    const updateOrderBy: (value: string) => void = (value: string) => {
        setOrderBy(value);
    }

    const openInShopify = (productId: number | null) => {
        const storeSplit = store.storeUrl.split('.');
        const storeURL = storeSplit[0];
        window.open(`https://admin.shopify.com/store/${storeURL}/products/${productId}`, '_blank');
    }

    useEffect(() => { 
        if(isLoading){
            updateProducts();
        }
    }, []);

    return (
        <div className="products">
            {!retrieveError ? (
                <>
                    <StoreReportProductFilterMenu 
                        setInShopify={updateShopify} 
                        setInMirror={updateMirror} 
                        setOnIndex={updateIndex} 
                        setNotInShopify={updateNotShopify} 
                        setNotInMirror={updateNotMirror} 
                        setNotOnIndex={updateNotIndex} 
                        setOrderBy={updateOrderBy} 
                        setClearFilters={setClearFilter} 
                        setSearch={updateSearch}
                    />
                    <StoreReportProductHeader/>
                </>
            ) : <></>}
            {!isLoading ? (
                products !== undefined && !retrieveError ? (
                    <div>
                        {currentProducts?.map((product, i) => (
                            <div key={i} className="products-list-view">
                                <StoreReportProductItem product={product} openInShopify={openInShopify}></StoreReportProductItem>
                            </div>
                        ))}
    
                        <StoreReportPagination
                            productsPerPage={productsPerPage}
                            totalProducts={totalProducts}
                            paginate={setCurrentPage}
                            currentPage={currentPage}
                            currentProductsCount={currentProductsCount}
                        />
                    </div>
                ) : retrieveError ? 
                    (
                        <p className="sync-error-retrieve">{store.syncState == 1 || store.syncV2IsRunning === 1 ? "The store's sync is currently in progress." : "Currently unable to retrieve the information."} Please try again later.</p>
                    )
                : (
                    <p className="no-products-message">No products available to display</p>
                )
            ) : (
                <div className="spinner-div">
                    <SpinnerCircularFixed size={35} thickness={140} speed={120} color="rgba(203, 216, 234, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                </div>
            )}
        </div>
    );
    
}