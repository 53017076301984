import Open from "../../../../assets/Open";
import { Product } from "../../../../types";

type Props =
{
    product: Product
    openInShopify: (value: number | null) => void
}

export default function StoreReportProductItem({ product, openInShopify }: Props) {
    return (
        <div className="list-item">
             <div className="item-line">
                <div className="item-column nameItem">{product.productname ? product.productname  : 'Not available'}</div>
                <div className="item-column idItem">{product.rsProductId ? product.rsProductId  : 'Not available'}</div>
                <div className="item-column shopifyItem">{product.shopifyId ? product.shopifyId  : 'Not available'}</div>
                <div className="item-column productItem">{product.shopifyProductHandle ? product.shopifyProductHandle  : 'Not available'}</div>
                <div className="item-column elasticItem">{product.elasticSearchId ? product.elasticSearchId  : 'Not available'}</div>
                <div className="item-column mirrorItem">{product.isInMirror ? 'YES' : 'NO'}</div>
                <div className="item-column inShopifyItem">{product.isInShopify
                    ? 
                    <button className="open-shopify-button" onClick={()=>(openInShopify(product.shopifyId))}>
                        <Open></Open> 
                    </button>
                    : 'NO'}
                </div>
                <div className="item-column onIndexItem">{product.isInIndex ? 'YES' : 'NO'}</div>
            </div>
        </div>
    )
}