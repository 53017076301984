export default function StoresListItemMenu() {
    return (
        <div className="list-item headerTitle">
            <div className="item-line header">
                <div className="item-column header state">State</div>
                <div className="item-column header StoreId">Store ID</div>
                <div className="item-column header friendlyName">Friendly Name</div>
                <div className="item-column header csv">CSV</div>
                <div className="item-column header storePlan">Store Plan</div>
                <div className="item-column header scheduled">Last refresh</div>
                <div className="item-column header frequency">Scheduled sync</div>
                <div className="item-column header sync">Sync</div>
            </div>
        </div>
    )
}