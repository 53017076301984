import { useEffect, useState } from "react";
import { Store, StoreDifferences } from '../../../types';
import StoreItem from './StoreItem';

type Props =
{
    store: Store,
    sync: (store: Store) => any,
}

export default function StoreItemContainer({ store, sync}: Props) 
{
    const [error, setError] = useState<undefined | string>();
    const [syncState, setSyncState] = useState<number>(store.syncState);


    const syncStore : () => Promise<void> = async () => 
    {
        const error = await sync(store);
        if(error === "") 
        {
            setSyncState(1);
            setError("");
        }
        else setError(error);
    }

    return (
        <div>
            <StoreItem store={store} error={error} syncState={syncState} syncStore={syncStore}></StoreItem>
        </div>
    )
}