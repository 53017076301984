import { Header } from "./Header"
import { SideBar } from "./SideBar"

type LayoutProps = {
  children: React.ReactNode,
  sidebar?: React.ReactNode,
}

export function Layout({ children, sidebar }: LayoutProps) {
  return (
    <div className="min-h-screen max-w-screen text-gray-700 font-sans">
      <div className="headerWrapper">
        <div className="headerContainer">
          <Header />
        </div>
      </div>
      <div className="layout">
        {sidebar && (
          <aside className="w-64 min-h-screen bg-gray-100">
            {sidebar}
          </aside>
        )}
        <main role="main" id="mainContent" className="relative">
          {children}
        </main>
      </div>
    </div>
  )
}



