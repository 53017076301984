import { Layout } from '../../../global/Layout';
import StoreCreationForm from './StoreCreationForm';

export default function StoreCreationContainer () 
{
    const handleGoBack = () => {
        window.location.href = '/stores';
    };

    return(
        <>
            <Layout>
            <div>
                <button className="back-button" onClick={handleGoBack}>Back to dashboard</button>
            </div>
                <StoreCreationForm></StoreCreationForm>
            </Layout>
        </>
    );
}