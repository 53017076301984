import { useEffect, useState } from "react";
import { getAllStores, syncCall } from "../../../lib/admin";
import { Store } from "../../../types";
import { Layout } from "../../global/Layout";
import StoresList from "./StoresList";
import StoresListHeader from "./StoresListHeader";
import StoresListFilterMenu from "./StoresListFilterMenu";
import { SideBar } from "../../global/SideBar";
import StoreReportPagination from "../report/StoreReportPagination";

export default function StoresListContainer() {
    const [stores, setStores] = useState<undefined | Store[]>(undefined);
    const [syncingStores, setSyncingStores] = useState<undefined | Store[]>([]);
    const [storesFilter, setStoresFilter] = useState<undefined | Store[]>(undefined);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({
        plan: -1,
        status: -1,
        isMultiStore: false,
        isSynchronizing: false
    });
    const [orderBy, setOrderBy] = useState<string>('default');
    const [clearFilter, setClearFilter] = useState<boolean>(false);
    const [viewList, setViewList] = useState<boolean>(false);

    const [storeType, setStoreType] = useState<'all' | 'ws' | 'kb'>(() => {
        return (localStorage.getItem('selectedStoreType') as 'all' | 'ws' | 'kb') || 'all';
    });

    const [currentPage, setCurrentPage] = useState(1);
    const storesPerPage = viewList ? 12 : 6;

    const indexOfLastStore = currentPage * storesPerPage;
    const indexOfFirstStore = indexOfLastStore - storesPerPage;
    const currentStores = storesFilter !== undefined 
        ? storesFilter.slice(indexOfFirstStore, indexOfLastStore)
        : stores?.slice(indexOfFirstStore, indexOfLastStore);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleViewChange: () => void = () => {
        setViewList(!viewList);
        setCurrentPage(1);
    }

    const searchStore: () => void = () => {
        if (search.length >= 2 && stores !== undefined && stores?.length > 0) {
            const isNumeric = /^\d+$/.test(search);
            const filterFunction = (s: Store) => {
                if (isNumeric) {
                    return s.id?.toString() === search;
                } else {
                    return s.friendlyName?.toLowerCase().includes(search.toLowerCase());
                }
            };
    
            let storesFind;
            if (filters.plan !== -1 || filters.status !== -1 || !filters.isMultiStore || !filters.isSynchronizing || orderBy !== 'default') {
                const filteredStores = handleFilters();
                storesFind = filteredStores?.filter(filterFunction);
            } else {
                storesFind = stores?.filter(filterFunction);
            }
    
            setStoresFilter(storesFind ?? []);
            setCurrentPage(1);
        }
    }

    useEffect(() => {
        if(search != "")
            searchStore();
        else setCurrentPage(1);
    }, [search]);

    const handleFilters = () => {
        if(stores != undefined){
            let filteredStores = [...stores];
            if(filteredStores !== undefined){
                if (filters.plan !== -1) 
                    filteredStores = filteredStores?.filter(s => s.storePlan === filters.plan || (filters.plan == 0 && s.storePlan == null));
                if (filters.status !== -1) 
                    filteredStores = filteredStores?.filter(s => s.liveState === filters.status || (filters.status == 0 && s.liveState == null));
                if (filters.isMultiStore)
                    filteredStores = filteredStores?.filter(s => s.isMultiStore);
                if(filters.isSynchronizing)
                    filteredStores = filteredStores?.filter(s => s.syncState === 1 || s.syncV2IsRunning === 1);
                if (orderBy === "name-asc") {
                    filteredStores = filteredStores.sort((a, b) => {
                        const nameA = a.friendlyName || "";
                        const nameB = b.friendlyName || "";
                        return nameA.localeCompare(nameB);
                    });
                } else if (orderBy === "name-desc") {
                    filteredStores = filteredStores.sort((a, b) => {
                        const nameA = a.friendlyName || "";
                        const nameB = b.friendlyName || "";
                        return nameB.localeCompare(nameA);
                    });
                }
                else if (orderBy === "last-sync-asc")
                    filteredStores = filteredStores.sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime());
                else if(orderBy === "last-sync-desc")
                    filteredStores = filteredStores.sort((a, b) => new Date(a.lastUpdate).getTime() - new Date(b.lastUpdate).getTime());
                else if (orderBy === "next-sync-asc")
                    filteredStores = filteredStores.sort((a, b) => new Date(b.nextSync).getTime() - new Date(a.nextSync).getTime());
                else if(orderBy === "next-sync-desc")
                    filteredStores = filteredStores.sort((a, b) => new Date(a.nextSync).getTime() - new Date(b.nextSync).getTime());
            
            }
            setCurrentPage(1);
            return filteredStores;
        }
    };

    useEffect(() => {
        if(!clearFilter){
            updateStores();
            setCurrentPage(1);
        }
    }, [filters, orderBy, storeType]);

    useEffect(() => { 
        if(clearFilter){
            setStoresFilter(undefined);
            setSearch("");
            setFilters({
                plan: -1,
                status: -1,
                isMultiStore: false,
                isSynchronizing: false
            });
            setClearFilter(false);
            setCurrentPage(1);
        }
    }, [clearFilter]);

    const getStoreType = (storePlan: number | null): 'ws' | 'kb' | 'unknown' => {
        if (storePlan === null || storePlan === 0) return 'unknown';
        if ([1, 2, 3, 4].includes(storePlan)) return 'ws';
        if ([5, 6].includes(storePlan)) return 'kb';
        return 'unknown';
    };

    const updateStores = async () => {
        setIsLoading(true);
        try {
            setStores([]);
            setSyncingStores([]);
            setStoresFilter(undefined);
            const newStores = await getAllStores();
            const filteredStores = newStores.filter((store: Store) => {
                if (storeType === 'all') return true;
                const currentStoreType = getStoreType(store.storePlan);
                return currentStoreType === storeType;
            });
            setStores(filteredStores)
            sortStoresDefault(filteredStores);
            const syncingStores = newStores.filter((store: Store) => store.syncState === 1 || store.syncV2IsRunning === 1);
            setSyncingStores(syncingStores);
            if (search !== ""){
                searchStore();
            } else if(filters.plan !== -1 || filters.status !== -1 || filters.isMultiStore || filters.isSynchronizing || orderBy !== 'default') {
                const furtherFilteredStores = handleFilters();
                setStoresFilter(furtherFilteredStores);
            } else {
                setStoresFilter(undefined);
            }
            setCurrentPage(1);
        }catch{}
        finally{setIsLoading(false);}
    }


    const syncStore: (store: Store) => Promise<string | undefined> = async (store: Store) => {
        if (!checkStoreSyncCount() && (localStorage.getItem('role')!='admin' && localStorage.getItem('role')!='sync_admin')) return ("There are already 3 or more shops synchronizing, please wait a moment.");
        if (!checkStoreTime(store)) return ("Last sync was less than 10 minutes ago, try again later.");
        const result = await syncCall(store.id);
        return result;
    }

    const checkStoreSyncCount: () => boolean = () => {
        if (stores !== undefined) {
            const count: number = stores.reduce(function (accumulator, s) {
                return s.syncState === 1 || s.syncV2IsRunning === 1 ? accumulator + 1 : accumulator;
            }, 0);
            return count < 4;
        }
        return false;
    }

    const checkStoreTime: (store: Store) => boolean = (store: Store) => {
        var now = new Date();
        var now_utc = new Date(now.toUTCString().slice(0, -5));
        var lastUpdate = new Date(store.lastUpdate);

        if (getMinDiff(now_utc, lastUpdate) <= 1) {
            return false;
        }
        else return true;
    }

    const getMinDiff: (startDate: any, endDate: any) => number = (startDate: any, endDate: any) => {
        const msInMinute = 60 * 1000;
        return Math.round(
            Math.abs(endDate - startDate) / msInMinute
        );
    }

    if (stores === undefined) {
        updateStores()
    }

    const updateSearch: (value: string) => void = (value: string) => {
        setSearch(value);
    }

    const updatePlan: (value: number) => void = (value: number) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            plan: value,
        }));
    }

    const updateStatus: (value: number) => void = (value: number) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            status: value,
        }));
    }

    const updateMultiStore = (isMultiStore: boolean) => {
        setFilters(prevFilters => ({
          ...prevFilters,
          isMultiStore: isMultiStore,
        }));
    }

    const updateSynchronizing = (isSync: boolean) => {
        setFilters(prevFilters => ({
          ...prevFilters,
          isSynchronizing: isSync,
        }));
    }

    const updateOrderBy: (value: string) => void = (value: string) => {
        setOrderBy(value);
    }

    const sortStoresDefault = (storesToSort: Store[]) => {
        const newStores = [...storesToSort];
        newStores.sort((a, b) => {
            const nameA = a.friendlyName;
            const nameB = b.friendlyName;

            if (nameA === null && nameB === null) return 0;
            if (nameA === null) return 1;
            if (nameB === null) return -1;

            return nameA.localeCompare(nameB);
        });
        setStores(newStores);
    };

    const handleStoreTypeChange = (newType: any) => {
        setStoreType(newType);
        setClearFilter(true);
        setFilters({
            plan: -1,
            status: -1,
            isMultiStore: false,
            isSynchronizing: false
        });
    };

    return (
        <Layout sidebar={ <SideBar onStoreTypeChange={handleStoreTypeChange} syncingStores={syncingStores} isLoading={isLoading}/> }>
            <StoresListHeader updateStores={updateStores} search={search} updateSearch={updateSearch} />
            <StoresListFilterMenu storeType={storeType} setStorePlan={updatePlan} setNewStatus={updateStatus} setMultiStore={updateMultiStore} setSynchronizing={updateSynchronizing} setOrderBy={updateOrderBy} setClearFilters={setClearFilter} handleViewChange={handleViewChange}/>
            {currentStores && <StoresList stores={currentStores} sync={syncStore} isLoading={isLoading} viewList={viewList}></StoresList>}
            <StoreReportPagination 
                productsPerPage={storesPerPage}
                totalProducts={storesFilter !== undefined ? storesFilter.length : stores?.length}
                paginate={paginate}
                currentProductsCount={currentStores?.length || 0}
                currentPage={currentPage}
            />
        </Layout>
    )
}