import  { useState, useEffect } from "react";
import { AccountType } from "../../../types";
import Delete from "../../../assets/Delete";

interface Props {
  defaultValue: number | null
  onSelectAccount: (accountId: number) => void
  accountOptions: AccountType[]
  onDeletedAccount: () => void
}

export function StoreAccountDropdown({ defaultValue, onSelectAccount, accountOptions, onDeletedAccount }: Props) {

  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");

  useEffect(()=>{
    if(accountOptions.length > 0) {
      searchNameWithSelected();
    }
  }, [accountOptions])

  const searchNameWithSelected = () : void => {
    const foundAccountType = accountOptions.find((type) => type.id === defaultValue);
    if(foundAccountType)
      setSelected(foundAccountType.name);
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    setSelected("");
    setInputValue(value);
    setOpen(value !== "");
  };
  
  const onItemSelected = (option: AccountType): void => {
    onSelectAccount(option.id);
    setInputValue(`${option.name} | ${option.id}`);
    setOpen(false);
    setSelected(option.name);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const clearAccountId = () : void => {
    setSelected("");
    setInputValue("");
    onDeletedAccount();
  }

  return (

    <div className="dropdown-container csvDropdown">
      <div className="input-container">
        <input 
        type="text"
        value ={inputValue}
        placeholder={selected != "" ? `${selected} | ${defaultValue}` : "Type To Search An Account"}
        onChange={onInputChange} 
        onKeyDown={handleKeyPress}
        className="input-search"
        />
        {selected != ""
          ?
          <button className="clear-CSV" onClick={clearAccountId}>
            <Delete/>
          </button>
          :
          <></>
        }
      </div>
      <div className={`dropdown ${open? "visible": "noVisible"}`}>
        {accountOptions.filter((account) => account.name.toString().toLowerCase().startsWith(inputValue.toLowerCase()))
            .map((item) => {
                return(
                    <div className="item-dropdown" key={item.id} onClick={()=>onItemSelected(item)}>{item.name} | {item.id}</div>
                )
            }
        )}
      </div>
    </div>
  );
}
