import { DateUTC } from './DateUTC';
import { DateLocalTime } from './DateLocalTime';
import { Link } from 'react-router-dom';
import StoresHeader from '../../assets/StoresHeader';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SignOut from '../../assets/SignOut';
import { setNewTimezonePreference } from '../../lib/admin';
import Globe from '../../assets/Globe';

export function Header () 
{
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('email');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('time-zone');
        navigate('/login', { replace: true });
    };

    const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('email') && localStorage.getItem('role') && localStorage.getItem('time-zone');

    const [useUTC, setUseUTC] = useState(true);

    const handleToggle = () => {
        const newPreference = !useUTC;
    
        setUseUTC(newPreference);
        localStorage.setItem('time-zone', newPreference ? 'inactive' : 'active');
        updatePreference();
        window.location.reload();
    };

    const updatePreference = async () => {
        await setNewTimezonePreference(useUTC);
    };

    useEffect(() => {
        const storedPreference = localStorage.getItem('time-zone');
        if (storedPreference === 'active') {
          setUseUTC(false);
        }
    }, []);

    return (
        <header className='admin-portal-header'>
            <div className='header-content'>
                <Link className='admin-portal-header-a' to='/stores'>
                    <StoresHeader />
                </Link>
            </div>
            <div className="header-date-container">
                <Globe/>
                {isLoggedIn &&(
                    <label>
                        LOCAL
                        <input
                            type="checkbox"
                            checked={useUTC}
                            onChange={handleToggle}
                        />
                        UTC
                    </label>
                )}
                {!isLoggedIn ? <DateUTC/> : useUTC ? <DateUTC /> : <DateLocalTime />}
            </div>
            {isLoggedIn && (
                <div className="management-div">
                    <button className = "btn-logout" onClick={logout}>
                        <SignOut></SignOut>
                    </button>
                </div>
            )}
        </header>
    )
}