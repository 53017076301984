import Arrow from "../../../assets/Arrow";
import { Store, StoreDifferences } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

type Props =
{
    store: Store, 
    productDifferences: StoreDifferences | null,
    collectionDifferences: StoreDifferences | null
}

export default function StoreCardDifferences ({store, productDifferences, collectionDifferences}: Props) 
{
    const navigate = useNavigate();
    const getReport = () => 
    {
        navigate(`/report/${store.id}`, { replace: true });
    }

    return (
        <div className="bottomArea compare">
            <div className="differences-info">
                <div className="compare-div">
                    <p className="progress-title">Quick compare</p>
                    <button className="unavailable">Compare</button>
                </div>
                <div className="differences-div">
                    <p className="progress-title">Sync results:</p>
                    <div className="sync-results">
                        <div className="listed-differences">
                            <p className="progress-title blueTitle">PRODUCTS</p>
                            <div className="differences-container">
                                <div className="column index">
                                    <p className="differences-column-title blackTitle">INDEX</p>
                                    <p className="differences-column-result">
                                        {productDifferences ? productDifferences.indexCount ? productDifferences.indexCount : 'na' : ' na'}
                                    </p>
                                </div>
                                <div className="column shopify">
                                    <p className="differences-column-title blackTitle">SHOPIFY</p>
                                    <p className="differences-column-result">
                                        {productDifferences ? productDifferences.shopifyCount ? productDifferences.shopifyCount : 'na' : ' na'}
                                    </p>
                                </div>
                                <div className="column system">
                                    <p className="differences-column-title blackTitle">RETAILSYS</p>
                                    <p className="differences-column-result">
                                        {productDifferences ? productDifferences.mirrorCount ? productDifferences.mirrorCount : 'na' : ' na'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="listed-differences rightCol">
                            <p className="progress-title blueTitle">COLLECTIONS</p>
                            <div className="differences-container">
                                <div className="column shopify">
                                    <p className="differences-column-title blackTitle">SHOPIFY</p>
                                    <p className="differences-column-result">
                                        {collectionDifferences ? collectionDifferences.shopifyCount ? collectionDifferences.shopifyCount : 'na' : ' na'}
                                    </p>
                                </div>
                                <div className="column system">
                                    <p className="differences-column-title blackTitle">RETAILSYS </p>
                                    <p className="differences-column-result">
                                    {collectionDifferences ? collectionDifferences.mirrorCount ? collectionDifferences.mirrorCount : 'na' : ' na'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="more-info difes">
               <p className="progress-title">See details</p>
               <button className = "btn-seeMore progr" onClick={() => getReport()}><Arrow></Arrow></button>
            </div>
        </div>
    )
}