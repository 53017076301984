import React from 'react';
import NextPage from '../../../assets/NextPage';
import PreviousPage from '../../../assets/PreviousPage';
import Next from '../../../assets/Next';
import Previous from '../../../assets/Previous';

type Props = {
    productsPerPage: number;
    totalProducts: number | undefined;
    paginate: (pageNumber: number) => void;
    currentProductsCount: number;
    currentPage: number;
}

export default function StoreReportPagination({ productsPerPage, totalProducts, paginate, currentPage, currentProductsCount }: Props) {
    const pageNumbers = [];
    const firstProductIndex = (currentPage - 1) * productsPerPage + 1;
    const lastProductIndex = Math.min(currentPage * productsPerPage, totalProducts || 0);
    const maxPageButtons = 5;

    if (totalProducts !== undefined) {
        for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
            pageNumbers.push(i);
        }
    }

    let displayPageNumbers = pageNumbers;
    if (pageNumbers.length > maxPageButtons) {
        const minIndex = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        const maxIndex = Math.min(minIndex + maxPageButtons - 1, pageNumbers.length);

        if (maxIndex - minIndex < maxPageButtons - 1) {
            displayPageNumbers = pageNumbers.slice(-maxPageButtons);
        } else {
            displayPageNumbers = pageNumbers.slice(minIndex - 1, maxIndex);
        }
    }

    return (
        <nav>
            <ul className='pagination'>
                <li className='page-item'>
                    <a
                        href='!#'
                        className='page-link'
                        onClick={(e) => {
                            e.preventDefault();
                            paginate(1);
                        }}
                    >
                        {<PreviousPage/>}
                    </a>
                </li>

                <li className='page-item'>
                    <a
                        href='!#'
                        className='page-link'
                        onClick={(e) => {
                            e.preventDefault();
                            paginate(currentPage > 1 ? currentPage - 1 : 1);
                        }}
                    >
                        {<Next/>}
                    </a>
                </li>

                {displayPageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <a
                            href='!#'
                            className='page-link'
                            onClick={(e) => {
                                e.preventDefault();
                                paginate(number);
                            }}
                        >
                            {number}
                        </a>
                    </li>
                ))}

                <li className='page-item'>
                    <a
                        href='!#'
                        className='page-link'
                        onClick={(e) => {
                            e.preventDefault();
                            paginate(currentPage < pageNumbers.length ? currentPage + 1 : pageNumbers.length);
                        }}
                    >
                        {<Previous/>}
                    </a>
                </li>

                <li className='page-item'>
                    <a
                        href='!#'
                        className='page-link'
                        onClick={(e) => {
                            e.preventDefault();
                            paginate(pageNumbers.length);
                        }}
                    >
                        {<NextPage/>}
                    </a>
                </li>
            </ul>
            <div className='currently'>
                {totalProducts != 0 ? (
                    `Currently displaying ${firstProductIndex}-${lastProductIndex} of ${totalProducts}`
                ) : (
                    `Currently displaying 0 - ${lastProductIndex} of ${totalProducts}`
                )}
            </div>
        </nav>
    );
}




