export default function StoreReportCategoriesHeader() {
    return (
        <div className="list-item headerItems">
            <div className="item-line">
                <div className="item-column header nameItem">Name</div>
                <div className="item-column header idItemRs">ID RetailSystem</div>
                <div className="item-column header shopifyItem">Shopify ID</div>
                <div className="item-column header productItem">Handle</div>
                <div className="item-column header elasticItem">Elastic Search ID</div>
                <div className="item-column header mirrorItem">In Mirror</div>
                <div className="item-column header inShopifyItem">In Shopify</div>
                <div className="item-column header onIndexItem">On Index</div>
            </div>
        </div>
    )
}
