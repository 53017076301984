export default function StoreReportProductHeader() {
    return (
        <div className="list-item headerItems">
            <div className="item-line">
                <div className="item-column header nameItem">Name</div>
                <div className="item-column header idItem">ID</div>
                <div className="item-column header shopifyItem">Shopify ID</div>
                <div className="item-column header productItem">Product Handle</div>
                <div className="item-column header elasticItem">Elastic Search ID</div>
                <div className="item-column header mirrorItem">In Mirror</div>
                <div className="item-column header inShopifyItem">In Shopify</div>
                <div className="item-column header onIndexItem">On Index</div>
            </div>
        </div>
    )
}


