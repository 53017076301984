import { Store } from '../../../types';
import syncz from '../../../assets/syncz.svg';
import PlanIcon from '../../../assets/plan/PlanIcon';
import formatDateToLocal from "../../../utils/formatDateToLocal";
import { useState } from "react";
import FilterSync from '../../../assets/FilterSync';
import StoreCardSyncModal from '../card/StoreCardSyncModal';

type Props =
    {
        store: Store,
        error: string | undefined,
        syncState: number,
        syncStore: () => void
    }

const minutesSync = [
    { value: "1", label: "1 minute" },
    { value: "2", label: "2 minutes" },
    { value: "30", label: "30 minutes" },
    { value: "60", label: "1 hour" },
    { value: "120", label: "2 hours" },
    { value: "180", label: "3 hours" },
    { value: "360", label: "6 hours" },
    { value: "720", label: "12 hours" },
    { value: "1080", label: "18 hours" },
    { value: "1440", label: "1 day" },
    { value: "4320", label: "3 days" },
    { value: "10080", label: "7 days" },
    { value: "43200", label: "30 days" }
];

const planNames: { [key: string]: string } = {
    '0': 'Unknown',
    '1': 'Bronze',
    '2': 'Silver',
    '3': 'Gold',
    '4': 'Platinum',
    '5': 'Kiosk',
    '6': 'Brochure'
};

const formatTime = (minutes: number) => {
    const time = minutesSync.find((timeObj) => timeObj.value === minutes.toString());
    return time ? time.label : `${minutes} minutes`;
};

const formatHour = (date_string: string) => {
    var new_date = new Date();
    new_date.setTime(Date.parse(date_string));
    return padTo2Digits(new_date.getUTCHours()) + ':' + padTo2Digits(new_date.getUTCMinutes());
};

function padTo2Digits(num: number) {
    return String(num).padStart(2, '0');
}

export default function StoreItem({ store, error, syncState, syncStore }: Props) {
    const planName = planNames[store.storePlan] || 'Unknown';

    const status = store.liveState == 1 ? 'In Progress' : store.liveState == 2 ? 'Live' : 'Offline';

    const [showModal, setShowModal] = useState(false);
                
    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
    setShowModal(false);
    };

    return (
        <div className="list-item">
            <div className="item-line">
                <div className="item-column stateRow">
                    <div className={`square ${store.liveState == 1 ? 'dev' : store.liveState == 2 ? 'live' : 'off'}`} data-tooltip={status}></div>
                </div>
                <div className="item-column storeIdRow">{store.id}</div>
                <div className={`item-column friendlyNameRow${store.friendlyName != undefined && store.friendlyName != null && store.friendlyName != "null" ? '' : ' alert'}`}>{store.friendlyName != undefined && store.friendlyName != null && store.friendlyName != "null" ? store.friendlyName : 'Not available'}</div>
                <div className={`item-column csvRow${store.dns != undefined && store.dns != null && store.dns != "null" ? '' : ' alert'}`}>{store.dns != undefined && store.dns != null && store.dns != "null" ? store.dns : 'Not available'}</div>
                <div className="item-column planRow">
                    <p className="store-plan">
                        <span className="store-plan-span">
                            <PlanIcon type={planName} />
                        </span>
                        <span className={`store-plan-${planName}`}>
                            {planName}
                        </span>
                    </p>
                </div>
                <div className="item-column scheduledRow">
                    {store.lastUpdate ? (
                        <p>
                            {localStorage.getItem('time-zone') === 'active' ? (
                                <>
                                    Last updated at:<br /> {formatDateToLocal(store.lastUpdate).replace(/T/, ' ').replace(/\..+/, '')} (Local)

                                </>
                            ) : (
                                <>
                                    Last updated at:<br /> {store.lastUpdate.replace(/T/, ' ').replace(/\..+/, '')} (UTC)

                                </>
                            )}
                        </p>
                    ) : (
                        'Information not available'
                    )}
                </div>
                <div className="item-column frequencyRow">
                    {store.automaticSyncEnabled && store.periodMinutes ? (
                        <div className="frequency-text">
                            <p>
                                Frequency: every {formatTime(store.periodMinutes)}
                            </p>
                            <p>
                                {store.nextSync
                                    ? `Next: ${localStorage.getItem('time-zone') === 'active'
                                        ? formatDateToLocal(store.nextSync).replace(/T/, ' ').replace(/\..+/, '') + ' (Local)'
                                        : store.nextSync.replace(/T/, ' ').replace(/\..+/, '') + ' (UTC)'
                                    }`
                                    : 'No upcoming sync scheduled'
                                }
                            </p>
                        </div>
                    ) : (
                        'Automatic sync is currently disabled'
                    )}
                </div>
                <div className="item-column SynchronizingRow">
                    {syncState === 1 || store.syncV2IsRunning === 1 ?
                        <div className="list-button-synchronising">
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Synchronizing
                        </div>
                        :
                        <button type="button" onClick={() => syncStore()} className="btn-list-sync">
                            <img src={syncz} alt="sync store" /> Start sync
                        </button>
                    }
                    <div className="basic-dates-store actions-panel syncMenuBtn">
                        <button type="button" onClick={openModal} className="btn btn-primary">
                            <FilterSync/> Sync
                        </button>
                    </div>
                </div>
            </div>
            <div className="modal-overlay">
                {showModal && (
                    <StoreCardSyncModal store={store} close={closeModal} syncStore={syncStore}/>
                )}
            </div>
        </div>
    )
}