import { useState, useEffect } from "react";

export function DateLocalTime() {
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }

  const formatDate = (date: Date) => {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  const [date, setDate] = useState<string>(formatDate(new Date()));

  useEffect(() => {
    const intervalId = setInterval(updateDate, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const updateDate = () => {
    setDate(formatDate(new Date()));
  }

  return (
    <div>
      <p className='admin-portal-date-utc-p' data-tooltip-content="(Local Time)">
        <span className='admin-portal-date-utc-span' >
          {date} (Local)
        </span>
      </p>
    </div>
  )
}
