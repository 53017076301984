export default function StoresHeader () {
    return (
        <svg width="168" height="26" viewBox="0 0 168 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M166.618 20.2282C166.036 20.2282 165.611 20.0508 165.345 19.6962C165.092 19.3288 164.965 18.8095 164.965 18.1382V6.47217H166.523V18.2522C166.523 18.4928 166.568 18.6702 166.656 18.7842C166.745 18.8855 166.846 18.9362 166.96 18.9362C167.011 18.9362 167.055 18.9362 167.093 18.9362C167.144 18.9235 167.214 18.9108 167.302 18.8982L167.511 20.0762C167.41 20.1268 167.29 20.1648 167.15 20.1902C167.011 20.2155 166.834 20.2282 166.618 20.2282Z" fill="white"/>
            <path d="M157.519 20.2281C156.746 20.2281 156.1 20.0001 155.581 19.5441C155.074 19.0754 154.821 18.4294 154.821 17.6061C154.821 16.5928 155.271 15.8201 156.17 15.2881C157.082 14.7434 158.52 14.3634 160.483 14.1481C160.483 13.7554 160.426 13.3818 160.312 13.0271C160.211 12.6724 160.021 12.3874 159.742 12.1721C159.476 11.9441 159.09 11.8301 158.583 11.8301C158.051 11.8301 157.551 11.9314 157.082 12.1341C156.613 12.3368 156.195 12.5648 155.828 12.8181L155.22 11.7351C155.651 11.4564 156.176 11.1904 156.797 10.9371C157.43 10.6711 158.114 10.5381 158.849 10.5381C159.976 10.5381 160.793 10.8864 161.3 11.5831C161.807 12.2671 162.06 13.1854 162.06 14.3381V20.0001H160.768L160.635 18.8981H160.578C160.147 19.2528 159.672 19.5631 159.153 19.8291C158.646 20.0951 158.102 20.2281 157.519 20.2281ZM157.975 18.9741C158.418 18.9741 158.836 18.8664 159.229 18.6511C159.622 18.4358 160.04 18.1318 160.483 17.7391V15.1741C158.95 15.3641 157.874 15.6491 157.253 16.0291C156.645 16.4091 156.341 16.8968 156.341 17.4921C156.341 18.0114 156.499 18.3914 156.816 18.6321C157.133 18.8601 157.519 18.9741 157.975 18.9741Z" fill="white"/>
            <path d="M152.286 20.2281C151.298 20.2281 150.608 19.9431 150.215 19.3731C149.835 18.8031 149.645 18.0621 149.645 17.1501V12.0391H148.277V10.8611L149.721 10.7661L149.911 8.18213H151.222V10.7661H153.711V12.0391H151.222V17.1691C151.222 17.7391 151.324 18.1825 151.526 18.4991C151.742 18.8031 152.115 18.9551 152.647 18.9551C152.812 18.9551 152.989 18.9298 153.179 18.8791C153.369 18.8158 153.54 18.7588 153.692 18.7081L153.996 19.8861C153.743 19.9748 153.464 20.0508 153.16 20.1141C152.869 20.1901 152.578 20.2281 152.286 20.2281Z" fill="white"/>
            <path d="M142.792 20.0001V10.7661H144.084L144.217 12.4381H144.274C144.591 11.8554 144.977 11.3931 145.433 11.0511C145.889 10.7091 146.377 10.5381 146.896 10.5381C147.264 10.5381 147.593 10.6014 147.884 10.7281L147.58 12.0961C147.428 12.0454 147.289 12.0074 147.162 11.9821C147.036 11.9568 146.877 11.9441 146.687 11.9441C146.295 11.9441 145.883 12.1024 145.452 12.4191C145.034 12.7358 144.667 13.2868 144.35 14.0721V20.0001H142.792Z" fill="white"/>
            <path d="M136.086 20.2281C135.338 20.2281 134.635 20.0381 133.977 19.6581C133.331 19.2781 132.805 18.7271 132.4 18.0051C132.007 17.2831 131.811 16.4154 131.811 15.4021C131.811 14.3634 132.007 13.4831 132.4 12.7611C132.805 12.0391 133.331 11.4881 133.977 11.1081C134.635 10.7281 135.338 10.5381 136.086 10.5381C136.846 10.5381 137.549 10.7281 138.195 11.1081C138.841 11.4881 139.36 12.0391 139.753 12.7611C140.158 13.4831 140.361 14.3634 140.361 15.4021C140.361 16.4154 140.158 17.2831 139.753 18.0051C139.36 18.7271 138.841 19.2781 138.195 19.6581C137.549 20.0381 136.846 20.2281 136.086 20.2281ZM136.086 18.9361C136.884 18.9361 137.523 18.6131 138.005 17.9671C138.499 17.3084 138.746 16.4534 138.746 15.4021C138.746 14.3381 138.499 13.4768 138.005 12.8181C137.523 12.1594 136.884 11.8301 136.086 11.8301C135.3 11.8301 134.661 12.1594 134.167 12.8181C133.673 13.4768 133.426 14.3381 133.426 15.4021C133.426 16.4534 133.673 17.3084 134.167 17.9671C134.661 18.6131 135.3 18.9361 136.086 18.9361Z" fill="white"/>
            <path d="M122.349 20.0001V7.53613H125.902C126.839 7.53613 127.656 7.65013 128.353 7.87813C129.049 8.10613 129.594 8.49247 129.987 9.03713C130.379 9.56913 130.576 10.2911 130.576 11.2031C130.576 12.5078 130.151 13.4768 129.303 14.1101C128.454 14.7435 127.346 15.0601 125.978 15.0601H123.926V20.0001H122.349ZM123.926 13.7681H125.788C126.877 13.7681 127.681 13.5655 128.201 13.1601C128.733 12.7421 128.999 12.0898 128.999 11.2031C128.999 10.3038 128.72 9.68313 128.163 9.34113C127.618 8.98647 126.801 8.80913 125.712 8.80913H123.926V13.7681Z" fill="white"/>
            <path d="M108.002 20.0001V10.7661H109.294L109.427 12.0961H109.484C109.928 11.6528 110.396 11.2854 110.89 10.9941C111.384 10.6901 111.948 10.5381 112.581 10.5381C113.557 10.5381 114.266 10.8484 114.709 11.4691C115.165 12.0771 115.393 12.9701 115.393 14.1481V20.0001H113.835V14.3571C113.835 13.4958 113.696 12.8688 113.417 12.4761C113.139 12.0834 112.695 11.8871 112.087 11.8871C111.619 11.8871 111.194 12.0074 110.814 12.2481C110.447 12.4888 110.029 12.8434 109.56 13.3121V20.0001H108.002Z" fill="white"/>
            <path d="M103.327 20V10.766H104.885V20H103.327ZM104.125 8.86596C103.821 8.86596 103.567 8.7773 103.365 8.59996C103.175 8.40996 103.08 8.15663 103.08 7.83996C103.08 7.53596 103.175 7.28896 103.365 7.09896C103.567 6.90896 103.821 6.81396 104.125 6.81396C104.429 6.81396 104.676 6.90896 104.866 7.09896C105.068 7.28896 105.17 7.53596 105.17 7.83996C105.17 8.15663 105.068 8.40996 104.866 8.59996C104.676 8.7773 104.429 8.86596 104.125 8.86596Z" fill="white"/>
            <path d="M87.5736 20.0001V10.7661H88.8656L88.9986 12.0961H89.0556C89.4609 11.6528 89.9043 11.2854 90.3856 10.9941C90.8669 10.6901 91.3799 10.5381 91.9246 10.5381C92.6339 10.5381 93.1849 10.6964 93.5776 11.0131C93.9829 11.3171 94.2806 11.7478 94.4706 12.3051C94.9519 11.7858 95.4396 11.3614 95.9336 11.0321C96.4276 10.7028 96.9533 10.5381 97.5106 10.5381C98.4606 10.5381 99.1636 10.8484 99.6196 11.4691C100.088 12.0771 100.323 12.9701 100.323 14.1481V20.0001H98.7646V14.3571C98.7646 13.4958 98.6253 12.8688 98.3466 12.4761C98.0679 12.0834 97.6373 11.8871 97.0546 11.8871C96.3706 11.8871 95.5979 12.3621 94.7366 13.3121V20.0001H93.1786V14.3571C93.1786 13.4958 93.0393 12.8688 92.7606 12.4761C92.4819 12.0834 92.0449 11.8871 91.4496 11.8871C90.7656 11.8871 89.9929 12.3621 89.1316 13.3121V20.0001H87.5736Z" fill="white"/>
            <path d="M80.1886 20.2282C79.0359 20.2282 78.1112 19.8102 77.4146 18.9742C76.7179 18.1255 76.3696 16.9348 76.3696 15.4022C76.3696 14.4015 76.5532 13.5402 76.9206 12.8182C77.3006 12.0835 77.7946 11.5198 78.4026 11.1272C79.0232 10.7345 79.6819 10.5382 80.3786 10.5382C80.9106 10.5382 81.3729 10.6332 81.7656 10.8232C82.1582 11.0132 82.5572 11.2728 82.9626 11.6022L82.8866 10.0252V6.47217H84.4636V20.0002H83.1716L83.0386 18.9172H82.9816C82.6269 19.2718 82.2089 19.5822 81.7276 19.8482C81.2462 20.1015 80.7332 20.2282 80.1886 20.2282ZM80.5306 18.9172C81.3412 18.9172 82.1266 18.4928 82.8866 17.6442V12.8182C82.4939 12.4635 82.1139 12.2165 81.7466 12.0772C81.3919 11.9252 81.0246 11.8492 80.6446 11.8492C80.1506 11.8492 79.7009 12.0012 79.2956 12.3052C78.9029 12.5965 78.5862 13.0082 78.3456 13.5402C78.1049 14.0595 77.9846 14.6738 77.9846 15.3832C77.9846 16.4852 78.2062 17.3528 78.6496 17.9862C79.0929 18.6068 79.7199 18.9172 80.5306 18.9172Z" fill="white"/>
            <path d="M68.9986 13.0271L68.4096 14.9271H72.1526L71.5636 13.0271C71.3356 12.3305 71.1203 11.6401 70.9176 10.9561C70.7149 10.2595 70.5123 9.55013 70.3096 8.82813H70.2336C70.0436 9.55013 69.8473 10.2595 69.6446 10.9561C69.4419 11.6401 69.2266 12.3305 68.9986 13.0271ZM65.1986 20.0001L69.4166 7.53613H71.2026L75.4206 20.0001H73.7296L72.5516 16.2001H68.0106L66.8136 20.0001H65.1986Z" fill="white"/>
            <path d="M56.1904 20.2282C55.3544 20.2282 54.5437 20.0699 53.7584 19.7532C52.9857 19.4365 52.3017 18.9869 51.7064 18.4042L52.9984 16.9032C53.4417 17.3212 53.9484 17.6632 54.5184 17.9292C55.0884 18.1825 55.6584 18.3092 56.2284 18.3092C56.9377 18.3092 57.476 18.1635 57.8434 17.8722C58.2107 17.5809 58.3944 17.1945 58.3944 16.7132C58.3944 16.1939 58.2107 15.8202 57.8434 15.5922C57.4887 15.3642 57.0327 15.1299 56.4754 14.8892L54.7654 14.1482C54.36 13.9709 53.961 13.7429 53.5684 13.4642C53.1884 13.1855 52.8717 12.8309 52.6184 12.4002C52.3777 11.9695 52.2574 11.4502 52.2574 10.8422C52.2574 10.1835 52.4347 9.59452 52.7894 9.07519C53.1567 8.54319 53.6507 8.12519 54.2714 7.82119C54.9047 7.50452 55.6267 7.34619 56.4374 7.34619C57.1594 7.34619 57.856 7.49186 58.5274 7.78319C59.1987 8.06186 59.775 8.44186 60.2564 8.92319L59.1354 10.3292C58.7427 9.99986 58.3247 9.74019 57.8814 9.55019C57.4507 9.36019 56.9694 9.26519 56.4374 9.26519C55.8547 9.26519 55.3797 9.39819 55.0124 9.66419C54.6577 9.91752 54.4804 10.2722 54.4804 10.7282C54.4804 11.0449 54.569 11.3109 54.7464 11.5262C54.9364 11.7289 55.1834 11.9062 55.4874 12.0582C55.7914 12.1975 56.1144 12.3369 56.4564 12.4762L58.1474 13.1792C58.882 13.4959 59.4837 13.9139 59.9524 14.4332C60.421 14.9399 60.6554 15.6429 60.6554 16.5422C60.6554 17.2135 60.478 17.8279 60.1234 18.3852C59.7687 18.9425 59.2557 19.3922 58.5844 19.7342C57.9257 20.0635 57.1277 20.2282 56.1904 20.2282Z" fill="white"/>
            <path d="M41.577 20.0002V7.57422H45.776C46.6373 7.57422 47.41 7.68822 48.094 7.91622C48.7907 8.14422 49.3353 8.53055 49.728 9.07522C50.1333 9.60722 50.336 10.3356 50.336 11.2602C50.336 12.2102 50.108 12.9766 49.652 13.5592C49.2087 14.1419 48.6133 14.5599 47.866 14.8132L50.83 20.0002H48.36L45.681 15.1362H43.781V20.0002H41.577ZM43.781 13.3882H45.548C46.3967 13.3882 47.0427 13.2109 47.486 12.8562C47.942 12.4889 48.17 11.9569 48.17 11.2602C48.17 10.5509 47.942 10.0569 47.486 9.77822C47.0427 9.48688 46.3967 9.34122 45.548 9.34122H43.781V13.3882Z" fill="white"/>
            <g clip-path="url(#clip0_3151_18)">
                <path d="M13.0207 2.68664C12.2766 2.68664 11.6566 2.0666 11.6566 1.32257C11.6566 0.578528 12.2766 -0.0415039 13.0207 -0.0415039C13.7647 -0.0415039 14.3847 0.578528 14.3847 1.32257C14.3434 2.10794 13.7647 2.68664 13.0207 2.68664Z" fill="white"/>
                <path d="M16.4102 6.1173C15.6661 6.1173 15.0461 5.49727 15.0461 4.75323C15.0461 4.00919 15.6661 3.38916 16.4102 3.38916C17.1542 3.38916 17.7743 4.00919 17.7743 4.75323C17.7743 5.49727 17.1542 6.1173 16.4102 6.1173Z" fill="white"/>
                <path d="M21.2464 6.1173C20.5024 6.1173 19.8824 5.49727 19.8824 4.75323C19.8824 4.00919 20.5024 3.38916 21.2464 3.38916C21.9905 3.38916 22.6105 4.00919 22.6105 4.75323C22.6105 5.49727 21.9905 6.1173 21.2464 6.1173Z" fill="white"/>
                <path d="M21.2464 10.9537C20.5024 10.9537 19.8824 10.3337 19.8824 9.58966C19.8824 8.84562 20.5024 8.22559 21.2464 8.22559C21.9905 8.22559 22.6105 8.84562 22.6105 9.58966C22.6105 10.3337 21.9905 10.9537 21.2464 10.9537Z" fill="white"/>
                <path d="M24.6359 14.3434C23.8919 14.3434 23.2719 13.7233 23.2719 12.9793C23.2719 12.2353 23.8919 11.6152 24.6359 11.6152C25.38 11.6152 26 12.2353 26 12.9793C26 13.7647 25.4213 14.3434 24.6359 14.3434Z" fill="white"/>
                <path d="M21.2464 17.774C20.5024 17.774 19.8824 17.154 19.8824 16.41C19.8824 15.6659 20.5024 15.0459 21.2464 15.0459C21.9905 15.0459 22.6105 15.6659 22.6105 16.41C22.6105 17.154 21.9905 17.774 21.2464 17.774Z" fill="white"/>
                <path d="M21.2464 22.6105C20.5024 22.6105 19.8824 21.9904 19.8824 21.2464C19.8824 20.5024 20.5024 19.8823 21.2464 19.8823C21.9905 19.8823 22.6105 20.5024 22.6105 21.2464C22.6105 21.9904 21.9905 22.6105 21.2464 22.6105Z" fill="white"/>
                <path d="M16.4102 22.6105C15.6661 22.6105 15.0461 21.9904 15.0461 21.2464C15.0461 20.5024 15.6661 19.8823 16.4102 19.8823C17.1542 19.8823 17.7743 20.5024 17.7743 21.2464C17.7743 21.9904 17.1542 22.6105 16.4102 22.6105Z" fill="white"/>
                <path d="M13.0207 25.9996C12.2766 25.9996 11.6566 25.3796 11.6566 24.6356C11.6566 23.8915 12.2766 23.2715 13.0207 23.2715C13.7647 23.2715 14.3847 23.8915 14.3847 24.6356C14.3434 25.3796 13.7647 25.9996 13.0207 25.9996Z" fill="white"/>
                <path d="M9.58984 22.6105C8.8458 22.6105 8.22577 21.9904 8.22577 21.2464C8.22577 20.5024 8.8458 19.8823 9.58984 19.8823C10.3339 19.8823 10.9539 20.5024 10.9539 21.2464C10.9539 21.9904 10.3339 22.6105 9.58984 22.6105Z" fill="white"/>
                <path d="M4.75357 22.6105C4.00953 22.6105 3.3895 21.9904 3.3895 21.2464C3.3895 20.5024 4.00953 19.8823 4.75357 19.8823C5.4976 19.8823 6.11764 20.5024 6.11764 21.2464C6.11764 21.9904 5.4976 22.6105 4.75357 22.6105Z" fill="white"/>
                <path d="M4.75357 17.774C4.00953 17.774 3.3895 17.154 3.3895 16.41C3.3895 15.6659 4.00953 15.0459 4.75357 15.0459C5.4976 15.0459 6.11764 15.6659 6.11764 16.41C6.11764 17.154 5.4976 17.774 4.75357 17.774Z" fill="white"/>
                <path d="M1.36407 14.3434C0.620032 14.3434 0 13.7233 0 12.9793C0 12.2353 0.620032 11.6152 1.36407 11.6152C2.10811 11.6152 2.72814 12.2353 2.72814 12.9793C2.6868 13.7647 2.10811 14.3434 1.36407 14.3434Z" fill="white"/>
                <path d="M4.75357 10.9537C4.00953 10.9537 3.3895 10.3337 3.3895 9.58966C3.3895 8.84562 4.00953 8.22559 4.75357 8.22559C5.4976 8.22559 6.11764 8.84562 6.11764 9.58966C6.11764 10.3337 5.4976 10.9537 4.75357 10.9537Z" fill="white"/>
                <path d="M4.75357 6.1173C4.00953 6.1173 3.3895 5.49727 3.3895 4.75323C3.3895 4.00919 4.00953 3.38916 4.75357 3.38916C5.4976 3.38916 6.11764 4.00919 6.11764 4.75323C6.11764 5.49727 5.4976 6.1173 4.75357 6.1173Z" fill="white"/>
                <path d="M9.58984 6.1173C8.8458 6.1173 8.22577 5.49727 8.22577 4.75323C8.22577 4.00919 8.8458 3.38916 9.58984 3.38916C10.3339 3.38916 10.9539 4.00919 10.9539 4.75323C10.9539 5.49727 10.3339 6.1173 9.58984 6.1173Z" fill="white"/>
                <path d="M17.8156 2.31485C17.3195 2.31485 16.9062 1.9015 16.9062 1.40547C16.9062 0.909448 17.3195 0.496094 17.8156 0.496094C18.3116 0.496094 18.7249 0.909448 18.7249 1.40547C18.7249 1.9015 18.3116 2.31485 17.8156 2.31485Z" fill="white"/>
                <path d="M24.6359 9.13517C24.1399 9.13517 23.7266 8.72181 23.7266 8.22579C23.7266 7.72976 24.1399 7.31641 24.6359 7.31641C25.132 7.31641 25.5453 7.72976 25.5453 8.22579C25.5453 8.72181 25.132 9.13517 24.6359 9.13517Z" fill="white"/>
                <path d="M24.6359 18.725C24.1399 18.725 23.7266 18.3117 23.7266 17.8156C23.7266 17.3196 24.1399 16.9062 24.6359 16.9062C25.132 16.9062 25.5453 17.3196 25.5453 17.8156C25.5453 18.3117 25.132 18.725 24.6359 18.725Z" fill="white"/>
                <path d="M17.8156 25.5453C17.3195 25.5453 16.9062 25.132 16.9062 24.6359C16.9062 24.1399 17.3195 23.7266 17.8156 23.7266C18.3116 23.7266 18.7249 24.1399 18.7249 24.6359C18.7249 25.132 18.3116 25.5453 17.8156 25.5453Z" fill="white"/>
                <path d="M8.18443 25.5453C7.68841 25.5453 7.27505 25.132 7.27505 24.6359C7.27505 24.1399 7.68841 23.7266 8.18443 23.7266C8.68046 23.7266 9.09381 24.1399 9.09381 24.6359C9.13515 25.132 8.7218 25.5453 8.18443 25.5453Z" fill="white"/>
                <path d="M1.40541 18.725C0.909387 18.725 0.496033 18.3117 0.496033 17.8156C0.496033 17.3196 0.909387 16.9062 1.40541 16.9062C1.90144 16.9062 2.31479 17.3196 2.31479 17.8156C2.31479 18.3117 1.90144 18.725 1.40541 18.725Z" fill="white"/>
                <path d="M1.40541 9.13517C0.909387 9.13517 0.496033 8.72181 0.496033 8.22579C0.496033 7.72976 0.909387 7.31641 1.40541 7.31641C1.90144 7.31641 2.31479 7.72976 2.31479 8.22579C2.31479 8.72181 1.90144 9.13517 1.40541 9.13517Z" fill="white"/>
                <path d="M8.18443 2.31485C7.68841 2.31485 7.27505 1.9015 7.27505 1.40547C7.27505 0.909448 7.68841 0.496094 8.18443 0.496094C8.68046 0.496094 9.09381 0.909448 9.09381 1.40547C9.13515 1.9015 8.7218 2.31485 8.18443 2.31485Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3151_18">
                <rect width="60" height="60" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}