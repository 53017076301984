import { Store, StoreShopifySyncInfo } from "../../../types"
import Divider from '@mui/material/Divider';
import {useState, useEffect } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { editShopifySyncInfo } from "../../../lib/admin";
import StoreShopifySaveModal from "./StoreShopifySaveModal";

type Props =
{
    store: Store,
    hasUnsavedChanges: boolean,
    setHasUnsavedChanges: (flag: boolean) => void,
    hasBeenUpdated: boolean,
    setHasBeenUpdated: (flag: boolean) => void
}

export default function StoreShopifySyncForm ({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) 
{
    const [showSyncSecret, setShowSyncSecret] = useState(store.syncApiSecret ? true : false);
    const [showToken, setShowToken] = useState(store.syncAdminToken ? true : false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const [updatedStore, setUpdatedStore] = useState<undefined | StoreShopifySyncInfo>(undefined);
    const [syncSecretKey, setSyncSecretKey] = useState(store.syncApiSecret != undefined ? store.syncApiSecret : "Not available");
    const [token, setToken] = useState(store.syncAdminToken != undefined ? store.syncAdminToken : "Not available");
    const [syncKey, setSyncKey] = useState(store.syncApiKey != undefined ? store.syncApiKey : "Not available");
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const closeModal = () => {
        setModalOpen(false);
    }

    const handleShowInfo = (field: 'syncSecret' | 'syncToken') => () =>{
        if (field === 'syncSecret') {
            setShowSyncSecret(!showSyncSecret);
        } else if (field === 'syncToken') {
          setShowToken(!showToken);
        }
    };

    const handleInputChange = () => {
        setHasUnsavedChanges(true);
    };

    const resetChanges = () => {
        setHasUnsavedChanges(false);
    }

    const setChanges = () => {
        setModalOpen(false);
        const editedStore: StoreShopifySyncInfo = {
            id: store.id,
            apiSecretKey: store.syncApiSecret,
            apiToken: store.syncAdminToken,
            apiKey: store.syncApiKey
        }
        if (syncSecretKey !== store.syncApiSecret && syncSecretKey.trim() !== "" && syncSecretKey!='Not available') editedStore.apiSecretKey = syncSecretKey;
        if (token !== store.syncAdminToken && token.trim() !== "" && token!='Not available') editedStore.apiToken = token;
        if (syncKey !== store.syncApiKey && syncKey.trim() !== "" && syncKey!='Not available') editedStore.apiKey = syncKey;
        setError(false);
        setUpdatedStore(editedStore);
        updateStore();
    }

    const updateStore = async () => {
        if(updatedStore==undefined) return;
        const response = await editShopifySyncInfo(updatedStore);
        
        if (response) {
            setSuccess(true);
            setError(false);
            setActionMessage("Changes saved successfully.");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } else {
            setSuccess(false);
            setError(true);
            setActionMessage("An error occurred. Please try again later.");
        }
    };
    useEffect(()=>{
        updatedStore && updateStore();
    },[updatedStore])

    return(
        
        <div className="sync-management">
            {modalOpen && (
                <StoreShopifySaveModal onSave={setChanges} onCancel={closeModal}/>
            )}
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1>Shopify Sync App</h1>
            </div>
            <Divider className="divider"/>
            <div className="form-container">
                <div className="column">
                    <div className="field">
                        <p className="field-label">Application Client Secret</p>
                        <div className="field-edit">
                            <input 
                                type={syncSecretKey == "Not available" ? 'text' : !showSyncSecret ? 'text' : 'password'}
                                id="syncSecret"
                                defaultValue={syncSecretKey}
                                onChange={(e) => {
                                    handleInputChange(); 
                                    setSyncSecretKey(e.target.value);
                                }} />
                            <button type="button" id="syncSecret" onClick={handleShowInfo('syncSecret')}>
                                {showSyncSecret ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Application Client Key</p>
                        <div className="field-edit">
                        <input 
                            type="text" 
                            defaultValue={syncKey}
                            onChange={(e) => {
                                handleInputChange(); 
                                setSyncKey(e.target.value);
                            }}/>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Admin API Token</p>
                        <div className="field-edit">
                            <input 
                                type={token == "Not available" ? 'text' : !showToken ? 'text' : 'password'}
                                id="syncToken" 
                                defaultValue={token}
                                onChange={(e) => {
                                    handleInputChange(); 
                                    setToken(e.target.value);
                                }} />
                            <button type="button" id="syncToken" onClick={handleShowInfo('syncToken')}>
                                {showToken ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Storefront API Token</p>
                        <div className="field-edit">
                            <input 
                                type={'text'}
                                id="syncStorefrontToken" 
                                defaultValue={'Not Available'}
                                onChange={() => null}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="error-container">
                    {error ? (
                        <span className="error-message">{actionMessage}</span>
                    ) : success ? (
                        <span className="success-message">{actionMessage}</span>
                    ) : null}
                </div>
                <div className="button-container">
                    <button className="edit-button" onClick={resetChanges}>
                        Cancel
                    </button>
                    <button
                        className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                        disabled={!hasUnsavedChanges}
                        onClick={()=>setModalOpen(true)}
                        >
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    );
}