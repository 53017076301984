import { ProductCategory, Store } from "../../../../types";
import { useState, useEffect } from "react";
import StoreReportProductCategoryHeader from "./StoreReportProductCategoryHeader";
import StoreReportProductCategoryItem from "./StoreReportProductCategoryItem";
import StoreReportPagination from "../StoreReportPagination";
import { SpinnerCircularFixed } from "../../../../assets/spinner/SpinnerCircularFixed";
import StoreReportProductCategoryFilterMenu from "./StoreReportProductCategoryFilterMenu";
import { getAllProductCategories } from "../../../../lib/admin";

type Props =
{
    store: Store
}

export default function StoreReportProductCategoryList({ store }: Props) {
    const [isLoading, setLoading] = useState(true);
    const [products, setProducts] = useState<undefined | ProductCategory[]>(undefined);
    const [filteredProducts, setFilteredProducts] = useState<undefined | ProductCategory[]>(undefined);
    const [clearFilter, setClearFilter] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [retrieveError, setRetrieveError] = useState<boolean>(false);
    const [searchBy, setSearchBy] = useState({
        products: true,
        categories: false,
    });

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 25;
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    const currentProducts = filteredProducts != undefined ? filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct) : products?.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalProducts = filteredProducts !== undefined ? filteredProducts.length : products?.length;
    const currentProductsCount = currentProducts?.length || 0;
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const searchProduct: () => void = () => {
        if (search.length >= 2 && products !== undefined && products?.length > 0) { 
            if(searchBy.products){
                const productsFind = products?.filter(p => p.productName?.toLocaleLowerCase().match(search.toLocaleLowerCase()));
                setFilteredProducts(productsFind ?? []);
            }else{
                const productsFind = products?.filter(p => p.categoryName?.toLocaleLowerCase().match(search.toLocaleLowerCase()));
                setFilteredProducts(productsFind ?? []);
            }
        }
    }

    useEffect(() => {
        if(search != "")
            searchProduct();
        else
            updateCategoryProducts();
    }, [search]);

    useEffect(() => {
        if(search != "")
            searchProduct();
        else
            updateCategoryProducts();
    }, [searchBy]);

    useEffect(() => { 
        if(clearFilter){
            setFilteredProducts(undefined);
            setSearch("");
            setClearFilter(false);
        }
    }, [clearFilter]);

    const updateCategoryProducts = async () => {
        setLoading(true);
        setFilteredProducts(undefined);
        setProducts([]);
        try{
            const newProducts = await getAllProductCategories(store.id);
            setProducts(newProducts);
            setLoading(false);
        }
        catch {
            setRetrieveError(true);
            setLoading(false);
        }
    }

    const updateSearch: (value: string) => void = (value: string) => {
        setSearch(value);
    }

    useEffect(() => { 
        if(isLoading){
            updateCategoryProducts();
        }
    }, []);

    const updateCategorySearch = (checked: boolean) => {
        setSearchBy({
            products: !checked,
            categories: checked
        });
    }

    const updateProductSearch = (checked: boolean) => {
        setSearchBy({
            products: checked,
            categories: !checked
        });
    }

    return (
        <div className="products">
            {!retrieveError ? (
                <>
                    <StoreReportProductCategoryFilterMenu 
                        setClearFilters={setClearFilter} 
                        setSearch={updateSearch}
                        setProductSearch={updateProductSearch}
                        setCategorySearch={updateCategorySearch}
                    />
                    <StoreReportProductCategoryHeader/>
                </>
            ) : <></>}
            {!isLoading ? (
                products !== undefined && !retrieveError && products.length > 0 ? (
                    <div>
                        {currentProducts?.map((product, i) => (
                            <div key={i} className="products-list-view">
                                <StoreReportProductCategoryItem productCategory={product}></StoreReportProductCategoryItem>
                            </div>
                        ))}
                        <StoreReportPagination
                            productsPerPage={productsPerPage}
                            totalProducts={totalProducts}
                            paginate={setCurrentPage}
                            currentPage={currentPage}
                            currentProductsCount={currentProductsCount}
                        />
                    </div>
                ) : retrieveError ? 
                    (
                        <p className="sync-error-retrieve">{store.syncState == 1 || store.syncV2IsRunning === 1? "The store's sync is currently in progress." : "Currently unable to retrieve the information."} Please try again later.</p>
                    )
                : (
                    <p className="no-products-message">Nothing available to display</p>
                )
            ) : (
                <div className="spinner-div">
                    <SpinnerCircularFixed size={35} thickness={140} speed={120} color="rgba(203, 216, 234, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                </div>
            )}
        </div>
    )
}