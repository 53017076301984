import {useState, useEffect, useRef } from "react";
import { AccountType, Store, StoreRSInfo } from "../../../types";
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem } from "@mui/material";
import { editStoreRSInfo, getCSVAccountData } from "../../../lib/admin";
import NoImage from '../../../assets/NoImage.jpg';
import { StoreAccountDropdown } from "./StoreAccountDropdown";

type Props =
{
    store: Store, 
    hasUnsavedChanges: boolean,
    setHasUnsavedChanges: (flag: boolean) => void,
    hasBeenUpdated: boolean,
    setHasBeenUpdated: (flag: boolean) => void
}

const planOptions = [
    { value: '0', label: 'Unknown' },
    { value: '1', label: 'Bronze' },
    { value: '2', label: 'Silver' },
    { value: '3', label: 'Gold' },
    { value: '4', label: 'Platinum' },
    { value: '5', label: 'Kiosk' },
    { value: '6', label: 'Brochure' }
];

const statusOptions = [
    { value: 0, label: 'Offline' },
    { value: 1, label: 'In Progress' },
    { value: 2, label: 'Live' },
];

export default function StoreRetailSystemForm ({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) 
{
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const [siteName, setSiteName] = useState(store.friendlyName ? store.friendlyName : "Not available");
    const [dns, setDns] = useState(store.dns ? store.dns : "Not available");
    const [liveState, setLiveState] = useState(store.liveState ? store.liveState : "Not available");
    const [accountGuid, setAccountGuid] = useState(store.accountGuid ? store.accountGuid : "Not available");
    const [accountId, setAccountId] = useState(store.accountId ? store.accountId : "Not available");
    const [retailSystemPlan, setRetailSystemPlan] = useState(store.storePlan ? store.storePlan.toString() : '0');
    const [logo, setLogo] = useState(store.logo != undefined ? store.logo : "No image selected");
    const [logoImage, setLogoImage] = useState(NoImage);
    const [updatedStore, setUpdatedStore] = useState<undefined | StoreRSInfo>(undefined);
    const [multiStoreChecked, setMultiStoreChecked] = useState(store.isMultiStore);
    const [orderDestinationAccountId, setOrderDestinationAccountId] = useState(store.orderDestinationAccountId ? store.orderDestinationAccountId : "Not available");
    const [accountOptions, setAccountOptions] = useState<AccountType[]>([]);

    const handleSaveChanges = () => {
        const editedStore: StoreRSInfo = {
            id: store.id,
            friendlyName: store.friendlyName,
            accountGuid: store.accountGuid,
            accountId: store.accountId,
            dns: store.dns, 
            storePlan: store.storePlan,
            logo: store.logo,
            isMultiStore: store.isMultiStore,
            liveState: store.liveState,
            orderDestinationAccountId: store.orderDestinationAccountId,
        }
        if (siteName !== store.friendlyName && siteName.trim() !== "" && siteName!='Not available') editedStore.friendlyName = siteName;
        if (accountGuid !== store.accountGuid && accountGuid.trim() !== "" && accountGuid!='Not available') editedStore.accountGuid = accountGuid;
        if (accountId !== store.accountId && accountId !== "Not available" && accountId !== "") {
            const parsedAccountId = typeof accountId === "string" ? parseInt(accountId) : accountId;
            if (!isNaN(parsedAccountId)) editedStore.accountId = parsedAccountId;  
        }
        if (liveState !== store.liveState && liveState !== "Not available" && liveState !== "") {
            const parsedLiveState = typeof liveState === "string" ? parseInt(liveState) : liveState;
            if (!isNaN(parsedLiveState)) editedStore.liveState = parsedLiveState;  
        }
        if (!store.storePlan || retailSystemPlan !== store.storePlan.toString()) editedStore.storePlan = parseInt(retailSystemPlan);
        if (logo !== store.logo && logo != "No image selected") editedStore.logo = logo;
        if(multiStoreChecked !== store.isMultiStore) editedStore.isMultiStore = multiStoreChecked;
        if (dns !== store.dns && dns.trim() !== "" && dns!='Not available') editedStore.dns = dns;
        if(orderDestinationAccountId == "none")
            editedStore.orderDestinationAccountId = null;
        if (orderDestinationAccountId !== store.orderDestinationAccountId && orderDestinationAccountId !== "Not available" && orderDestinationAccountId !== "") {
            const parsedOrderId = typeof orderDestinationAccountId === "string" ? parseInt(orderDestinationAccountId) : orderDestinationAccountId;
            if (!isNaN(parsedOrderId)) editedStore.orderDestinationAccountId = parsedOrderId;  
        }
        setError(false);
        setUpdatedStore(editedStore);
        updateStore();
    };

    const updateStore = async () => {
        if(updatedStore==undefined) return;
        const response = await editStoreRSInfo(updatedStore);
        
        if (response) {
            setSuccess(true);
            setError(false);
            setActionMessage("Changes saved successfully.");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } else {
            setSuccess(false);
            setError(true);
            setActionMessage("An error occurred. Please try again later.");
        }
    };

    useEffect(()=>{
        updatedStore && updateStore();
    },[updatedStore])

    const handleCancelChanges = () => {
        setSiteName(store.friendlyName ? store.friendlyName : "Not available");
        setAccountGuid(store.accountGuid ? store.accountGuid : "Not available");
        setDns(store.dns ? store.dns : "Not available");
        setAccountId(store.accountId ? store.accountId : "Not available");
        setLogo(store.logo ? store.logo : NoImage);
        setLiveState(store.liveState ? store.liveState : "Not available");
        setOrderDestinationAccountId(store.orderDestinationAccountId ? store.orderDestinationAccountId : "Not available");
        setRetailSystemPlan(store.storePlan.toString());
        setHasUnsavedChanges(false);
        setError(false);
        setSuccess(false);
    };

    const handleFieldChange = () => {
        setHasUnsavedChanges(true);
    };

    const renderLogoImage = () => {
        if (store.logo) {
          const img = new Image();
          img.src = store.logo;
          img.onload = () => {
            setLogoImage(store.logo);
          };
          img.onerror = () => {
            setLogoImage(NoImage);
          };
        } else {
          setLogoImage(NoImage);
        }
    };

    useEffect(() => {
        renderLogoImage();
    }, []);

    useEffect(()=>{
        updateDropdown();
    }, [])

    const updateDropdown = async () => {
        const response = await getCSVAccountData();
        setAccountOptions(response);
    }

    const onDeletedAccount = () => {
        setOrderDestinationAccountId("none");
        setHasUnsavedChanges(true);
    }

    const onSelectedAccount = (id: number) => {
        if (accountOptions) {
          const accountSelected = accountOptions.find(account => account.id === id);
          if (accountSelected) {
            setOrderDestinationAccountId(id);
            setHasUnsavedChanges(true);
          }
        }
    }

    return(
        <div className="sync-management">
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1><img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/retail.png?v=1696262901" alt="" />RetailSystem</h1>
            </div>
            <Divider className="divider"/>
            <div className="form-container">
                <div className="column">
                    <div className="field specific-field logo">
                        <div className="image-input-container">
                            <img className="logo-image" src={logoImage} alt="Site Logo" />
                        </div>
                    </div>
                    <div className="field specific-field">
                        <div className="field-content">
                            <p className="field-label">Site Name</p>
                            <TextField
                                required
                                id="outlined-required"
                                value={siteName}
                                onChange={(e) => {
                                    setSiteName(e.target.value);
                                    handleFieldChange();
                                }}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">BigFurnitureData Store</p>
                        <div className="field-edit">
                            <FormControlLabel 
                                control={
                                    <Switch checked={multiStoreChecked} onChange={() => setMultiStoreChecked(!multiStoreChecked)}/>
                                    }
                                label={multiStoreChecked ? "Yes" : "No"}
                                labelPlacement="start" />
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">RetailSystem Plan</p>
                            <Select
                            
                            value={retailSystemPlan}
                            onChange={(e) => {
                                setRetailSystemPlan(e.target.value as string);
                                handleFieldChange();
                            }}
                            >
                                {planOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Select>
                    </div>
                    <div className="field">
                        <p className="field-label">Live Status</p>
                            <Select
                            value={liveState}
                            onChange={(e) => {
                                setLiveState(e.target.value);
                                handleFieldChange();
                            }}
                            >
                                {statusOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </Select>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <p className="field-label">Store Logo</p>
                        <TextField
                            required
                            id="outlined-required"
                            value={logo}
                            onChange={(e) => {
                                setLogo(e.target.value);
                                handleFieldChange();
                            }}
                        />
                    </div>
                    <div className="field">
                        <p className="field-label">DNS</p>
                            <TextField
                            required
                            id="outlined-required"
                            value={dns}
                            onChange={(e) => {
                                setDns(e.target.value);
                                handleFieldChange();
                            }}
                            />
                    </div>
                    <div className="field">
                        <p className="field-label">Account Guid</p>
                            <TextField
                            required
                            id="outlined-required"
                            value={accountGuid}
                            onChange={(e) => {
                                setAccountGuid(e.target.value);
                                handleFieldChange();
                            }}
                            />
                    </div>
                    <div className="field">
                        <p className="field-label">Account Id</p>
                            <TextField
                            required
                            id="outlined-required"
                            value={accountId}
                            onChange={(e) => {
                                setAccountId(e.target.value);
                                handleFieldChange();
                            }}
                            type="number"
                            />
                    </div>
                    <div className="field">
                        <p className="field-label">Order Destination Account Id</p>
                            <StoreAccountDropdown
                                defaultValue={store.orderDestinationAccountId}
                                accountOptions={accountOptions}
                                onSelectAccount={onSelectedAccount}
                                onDeletedAccount={onDeletedAccount}
                            />
                    </div>
                </div>
            </div>
            <Divider></Divider>
            <div className="footer">
                <div className="error-container">
                {error ? (
                    <span className="error-message">{actionMessage}</span>
                ) : success ? (
                    <span className="success-message">{actionMessage}</span>
                ) : null}
                </div>
                <div className="button-container">
                    <div>
                        <button className="edit-button" onClick={()=>handleCancelChanges()}>
                            Cancel
                        </button>
                        <button
                            className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                            onClick={handleSaveChanges}
                            disabled={!hasUnsavedChanges}
                            >
                            Save changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}