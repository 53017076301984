import { Store, StoreSyncInfo } from "../../../types";
import { editStoreSync, syncCall } from "../../../lib/admin";
import { useState, useEffect } from "react";
import syncz from '../../../assets/syncz.svg';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { MenuItem } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import DateIcon from "../../../assets/DateIcon";
import formatDateToLocal from "../../../utils/formatDateToLocal";

type Props =
    {
        store: Store,
        hasUnsavedChanges: boolean,
        setHasUnsavedChanges: (flag: boolean) => void,
        hasBeenUpdated: boolean,
        setHasBeenUpdated: (flag: boolean) => void
    }

export default function StoreSyncForm({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const [isLocked, setLock] = useState(!store.automaticSyncEnabled);
    const [repeatSyncValue, setRepeatSyncValue] = useState<number>(store.periodMinutes != null ? store.periodMinutes : 30);
    const [selectedDate, setSelectedDate] = useState<null | Date>(store.syncStartTime ? new Date(store.syncStartTime) : null);
    const [storeChanges, setStoreChanges] = useState<undefined | StoreSyncInfo>(undefined);
    const [selectedSyncType, setSelectedSyncType] = useState(store.executionMode != null ? store.executionMode == 0 ? "one-time" : "permanent" : "one-time");
    const [syncState, setSyncState] = useState<number>(store.syncState);


    const syncStore : () => Promise<void> = async () => 
    {
        const response = await syncCall(store.id);
        if(response == "") 
        {
            setSyncState(1);
            setActionMessage("Synchronization started.");
            setError(false);
            setSuccess(true);
        }
        else{
            setActionMessage(response);
            setError(true);
            setSuccess(false);
        } 
    }

    const minutesSync = [
        { value: "1", label: "1 minute" },
        { value: "2", label: "2 minutes" },
        { value: "30", label: "30 minutes" },
        { value: "60", label: "1 hour" },
        { value: "120", label: "2 hours" },
        { value: "180", label: "3 hours" },
        { value: "360", label: "6 hours" },
        { value: "720", label: "12 hours" },
        { value: "1080", label: "18 hours" },
        { value: "1440", label: "1 day" },
        { value: "4320", label: "3 days" },
        { value: "10080", label: "7 days" },
        { value: "43200", label: "30 days" }
    ];

    const repeatMinuteItems = minutesSync.map((option) => (
        <MenuItem key={option.value} value={option.value}>
            <em>{option.label}</em>
        </MenuItem>
    ));

    const handleLockToggle = () => {
        const updatedLock = !isLocked;
        setLock(updatedLock);
    };

    useEffect(() => {
        if(isLocked && store.automaticSyncEnabled){
            setChanges();
        }else if (!isLocked && !store.automaticSyncEnabled){
            setHasUnsavedChanges(true);
            setError(false);
            setSuccess(false);
        }
    }, [isLocked])

    useEffect(() => {
        storeChanges && sendStores();
    }, [storeChanges])
    

    const handleDateChange = (newDate: Date | null) => {
        const currentDate = dayjs();

        if (newDate && dayjs(newDate).isBefore(currentDate)) {
            setActionMessage("Please select a future date.");
            setError(true);
            return;
        }
        setError(false);
        setHasUnsavedChanges(true);
        setSelectedDate(newDate);
    };

    const handleExecutionMode = (value: string) => {
        setSelectedSyncType(value);
        setHasUnsavedChanges(true);
    };

    const handleRepeatSyncValue = (value: number) => {
        setRepeatSyncValue(value);
        setHasUnsavedChanges(true);
    }

    const resetForm = () => {
        setError(false);
        setSuccess(false);
        setActionMessage("An error occurred. Please try again later.");
        setLock(!store.automaticSyncEnabled);
        setHasUnsavedChanges(false);
    };

    const setChanges = () => {
        if (isLocked) {
            const updatedStoreChanges: StoreSyncInfo = {
                id: store.id,
                automaticSyncEnabled: false,
                executionMode: store.executionMode,
                syncStartTime: store.syncStartTime,
                periodMinutes: store.periodMinutes,
                nextSync: null
            };
            setError(false);
            setStoreChanges(updatedStoreChanges);

        } else {
            if (selectedDate === null) {
                setError(true);
                setSuccess(false);
                setActionMessage("Select a valid date.");
                return;
            }

            if (localStorage.getItem('time-zone') === 'active') {
                if (selectedDate < new Date()) {
                    setError(true);
                    setSuccess(false);
                    setActionMessage("Select a valid date.");
                    return;
                }
            } else {
                const currentUTC = new Date().toISOString();
                
                const year = selectedDate.getFullYear();
                const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                const day = String(selectedDate.getDate()).padStart(2, '0');
                const hours = String(selectedDate.getHours()).padStart(2, '0');
                const minutes = String(selectedDate.getMinutes()).padStart(2, '0');
                const seconds = String(selectedDate.getSeconds()).padStart(2, '0');

                serverTimezone = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

                if (serverTimezone < currentUTC) {
                    setError(true);
                    setSuccess(false);
                    setActionMessage("Select a valid date.");
                    return;
                }
            }

            if (!selectedSyncType) {
                setError(true);
                setSuccess(false);
                setActionMessage("Select a type of synchronization setting.");
                return;
            }

            var serverTimezone = "";
            if(localStorage.getItem('time-zone') === 'active'){
                serverTimezone = selectedDate.toISOString();
            } else {
                const year = selectedDate.getFullYear();
                const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
                const day = String(selectedDate.getDate()).padStart(2, '0');
                const hours = String(selectedDate.getHours()).padStart(2, '0');
                const minutes = String(selectedDate.getMinutes()).padStart(2, '0');
                const seconds = String(selectedDate.getSeconds()).padStart(2, '0');

                serverTimezone = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
            }

            const updatedStoreChanges: StoreSyncInfo = {
                id: store.id,
                automaticSyncEnabled: true,
                executionMode: selectedSyncType === "one-time" ? 0 : 1,
                syncStartTime: serverTimezone,
                periodMinutes: repeatSyncValue,
                nextSync: serverTimezone
            };
            setError(false);
            setStoreChanges(updatedStoreChanges);
        }
    }

    useEffect(() => {
        storeChanges && sendStores();
    }, [storeChanges])

    const sendStores = async () => {
        if (storeChanges == undefined) return;
        const response = await editStoreSync(storeChanges);

        if (response) {
            setSuccess(true);
            setError(false);
            setActionMessage("Changes saved successfully.");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } else {
            setSuccess(false);
            setError(true);
            setActionMessage("An error occurred. Please try again later.");
        }
    };

    return (
        <div className="sync-management syncPanel">
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1><img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync.png?v=1696262901" alt="sync store" />Sync</h1>
            </div>
            <Divider className="divider" />
            <p className="form-title">Automatic Sync is {store.automaticSyncEnabled ? "Active" : "Inactive"}</p>

            <div className="SyncTopArea">
                <div className="SyncTopArea__container grid">

                    <div className="SyncTopArea__grid">

                        <div className="SyncTopArea__leftColumn">

                            <div className="SyncTopArea__fistRow">
                                <div className="SyncTopArea__iconArea">
                                    <div className="inconSync">
                                        <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/sync.png?v=1696262901" alt="" />
                                    </div>
                                    <div className="syncState">
                                        <div className="column dates">
                                            <div className="field">
                                                <p className="field-label SyncState">Sync State</p>
                                                <p className="sync-state">
                                                    {
                                                        store.syncState === 0
                                                            ? " Idle"
                                                            : store.syncState === 1 || store.syncV2IsRunning === 1
                                                                ? " Running"
                                                                : store.syncState === 3
                                                                    ? " Error"
                                                                    : " Unknown State"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="SyncTopArea__lineArea">
                                    <div className="next">
                                        <div className="field">
                                            <p className="field-label nextSync">Next <span>Sync</span></p>
                                            <p className="date">
                                                {localStorage.getItem('time-zone') === 'active'
                                                    ? (store.nextSync
                                                        ? `${formatDateToLocal(store.nextSync).replace(/T/, ' ').replace(/\..+/, '')} (Local)`
                                                        : 'Not Available (Local)'
                                                    )
                                                    : (store.nextSync
                                                        ? `${store.nextSync.replace(/T/, ' ').replace(/\..+/, '')} (UTC)`
                                                        : 'Not Available (UTC)'
                                                    )
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="SyncTopArea__secondRow">
                                <div className="SyncTopArea__iconArea">
                                    <div className="inconSync dateicon">
                                        <DateIcon></DateIcon>
                                    </div>
                                    <div className="last">
                                        <div className="column">
                                            <div className="field">
                                                <p className="field-label">Last catalog <span>sync execution</span></p>
                                                <p className="date">
                                                {localStorage.getItem('time-zone') === 'active'
                                                    ? (store.lastUpdate
                                                        ? `${formatDateToLocal(store.lastUpdate).replace(/T/, ' ').replace(/\..+/, '')} (Local)`
                                                        : 'Not Available (Local)'
                                                    )
                                                    : (store.lastUpdate
                                                        ? `${store.lastUpdate.replace(/T/, ' ').replace(/\..+/, '')} (UTC)`
                                                        : 'Not Available (UTC)'
                                                    )
                                                }
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="SyncTopArea__lineArea">
                                    <div className="column last">
                                        <div className="field">
                                            <p className="field-label">Last stock <span>sync execution</span></p>
                                            <p className="date">
                                                {localStorage.getItem('time-zone') === 'active'
                                                    ? (store.lastStockUpdate
                                                        ? `${formatDateToLocal(store.lastStockUpdate).replace(/T/, ' ').replace(/\..+/, '')} (Local)`
                                                        : 'Not Available (Local)'
                                                    )
                                                    : (store.lastStockUpdate
                                                        ? `${store.lastStockUpdate.replace(/T/, ' ').replace(/\..+/, '')} (UTC)`
                                                        : 'Not Available (UTC)'
                                                    )
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="SyncTopArea__rightColumn">
                            <button type="button" onClick={() => syncStore()} className="btn btn-primary SyncNowBtn"><img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/x1ZlYh_2.png?v=1697580579" alt="" /> Sync Now</button>
                        </div>

                    </div>
                </div>
                {
                    localStorage.getItem('role') !== 'sync_admin'
                    ?
                    <></>
                    :
                    <div className="SyncTopArea__btnArea">
                        <div className="lock-check-wrapper">
                            <div className="lock-check">
                                <div className="lock">
                                    {
                                        isLocked ?
                                            <LockIcon></LockIcon>
                                            :
                                            <LockOpenIcon></LockOpenIcon>
                                    }
                                </div>
                                <Tooltip title="Enabling this option will run the automatic sync configuration.">
                                    <FormControlLabel className="enableAutomatic"
                                        control={<Switch checked={!isLocked} onChange={handleLockToggle} />}
                                        label="Enable automatic sync"
                                        labelPlacement="start" />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                }                               
            </div>
            {localStorage.getItem('role') !== 'sync_admin'
                ?
                <></>
                :
                <>
                    <div className="SyncBottomArea">
                        <div className="SyncBottomArea__container">
                            <div className={isLocked ? 'locked-form' : 'form-container'}>

                                <div className="SyncBottomArea__topArea">
                                    <p className="form-title">Automatic Sync is Active</p>
                                    <p className="lock">Lock <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/G46WBp_2.png?v=1697582030" alt="" /></p>
                                    <p className="unlocked">Unlocked <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Frame_44.png?v=1697720851" alt="" /></p>
                                </div>

                                <div className="SyncBottomArea__firstRow">

                                    <div className="repeat startRow">
                                        <div className="field">
                                            <p className="field-label">Start</p>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DateTimePicker
                                                    value={store.syncStartTime == null 
                                                        ?  null
                                                        : localStorage.getItem('time-zone') == 'active'
                                                        ? new Date(formatDateToLocal(store.syncStartTime))
                                                        : new Date(store.syncStartTime)
                                                    }
                                                    onChange={handleDateChange}
                                                    disablePast
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div className="mode">
                                        <div className="field">
                                            <p className="field-label">Execution mode</p>

                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={selectedSyncType}
                                                    onChange={(event) => handleExecutionMode(event.target.value)}
                                                >
                                                    <div className="executionMode">
                                                        <div className="field-form executionP"><FormControlLabel value="one-time" control={<Radio />} label="One Time" /></div>
                                                        <div className="field-form executionP"><FormControlLabel value="permanent" control={<Radio />} label="Permanent" /></div>
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>

                                        </div>
                                    </div>
                                </div>

                                <div className="SyncBottomArea__secondRow">
                                    <div className="repeat">
                                        <div className="column">

                                            <div className="field">
                                                <p className="field-label">Repeat Sync Every</p>                                        
                                                <Select                                    
                                                    value={String(repeatSyncValue)}
                                                    variant="outlined"
                                                    onChange={(event) => handleRepeatSyncValue(Number(event.target.value))}
                                                >
                                                    {repeatMinuteItems}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btns">
                                        <div className="button-container">
                                            <div className="btnFooter">
                                                <button className="edit-button cancelBTN" onClick={resetForm}>
                                                    Cancel
                                                </button>
                                                <button
                                                    className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                                                    onClick={() => setChanges()}
                                                    disabled={!hasUnsavedChanges}
                                                >
                                                    Save changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Divider></Divider>
                    <div className="footer">
                        <div className="error-container">
                            {error ? (
                                <span className="error-message">{actionMessage}</span>
                            ) : success ? (
                                <span className="success-message">{actionMessage}</span>
                            ) : null}
                        </div>
                    </div>                            
                </>     
            }   
        </div>
    );
}