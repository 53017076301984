import { Store } from '../../../types';
import {useState, useEffect } from "react";
import { Layout } from "../../global/Layout";
import { useParams } from "react-router-dom";
import { getStoreById } from "../../../lib/admin";
import StoreReportHeader from "./StoreReportHeader";
import StoreReportMenu from './StoreReportMenu';
import { SpinnerCircularFixed } from '../../../assets/spinner/SpinnerCircularFixed';

export default function StoreReportContainer() {
    const { storeId } = useParams<{ storeId: string }>();
    const [store, setStore] = useState<Store | null>(null);
    const [loading, setLoading] = useState(true);

    const updateStore = async () => {
        if (storeId) {
            try {
                const newStore = await getStoreById(parseInt(storeId));
                setStore(newStore);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setStore(null);
            }
        } else {
            setStore(null);
            setLoading(false);
        }
    };

    useEffect(() => {
        updateStore();
    }, [storeId]);

    return (
        <Layout>
            {loading ? (
                <div className="spinner-div spinnerReport">
                    <SpinnerCircularFixed className='' size={35} thickness={140} speed={120} color="rgba(203, 216, 234, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                </div>
            ) : store ? (
                <div>
                    <StoreReportHeader store={store} />
                    <StoreReportMenu store={store} />
                </div>
            ) : (
                <p>Not found</p>
            )}
        </Layout>
    )
}
