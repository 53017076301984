import React from 'react';
import { Navigate } from 'react-router-dom';

export const isAuthenticated = () => {
  return !!localStorage.getItem('token') && !!localStorage.getItem('email') && !!localStorage.getItem('role') && !!localStorage.getItem('time-zone') && (localStorage.getItem('role')=='admin'|| localStorage.getItem('role')=='content_manager' || localStorage.getItem('role')=='sync_admin');
};

const Authorization = ({ element, ...rest }) => {
    if (isAuthenticated()) {
      return element;
    } else {
      return <Navigate to="/login" replace />;
    }
};
  
export default Authorization;