import React, { useState } from 'react';
import { Store } from "../../../types";
import { Skeleton } from "@mui/material";
import NoImage from '../../../assets/NoImage.jpg';
import { StoreWidgetCard } from './StoreListSyncWidgetCard';
import { SpinnerCircularFixed } from '../../../assets/spinner/SpinnerCircularFixed';
import PlusIcon from '../../../assets/PlusIcon';
import Warning from '../../../assets/warning';

type StoreListSyncWidgetProps = {
    stores: undefined | Store[];
    isLoading: boolean;
};

export default function StoresListSyncWidget({ stores, isLoading }: StoreListSyncWidgetProps) {
    if (isLoading) {
        return (
            <div className="sync-widget-loading">
                <div className="spinner">
                    <SpinnerCircularFixed />
                </div>
            </div>
        );
    }

    if (!stores || stores.length === 0) {
        return (
            <div className="sync-widget">
                <div className="content">
                    <p className="none">No stores are currently being synchronized</p>
                </div>
            </div>
        );
    }

    const displayedStores = stores.slice(0, 5);
    const hasMoreStores = stores.length > 5;

    return (
        <div className="sync-widget">
            <div className="content">
                <div className="list">
                    {displayedStores?.map((store, index) => (
                        store !== undefined && <StoreWidgetCard key={index} store={store} />
                    ))}
                </div>
                {hasMoreStores && (
                    <div className="warning-message">
                        <div className="warning-number-container">
                            <p className="warning-number"><PlusIcon /> {stores.length - 5}</p>
                            <p className="warning-number additional"> stores...</p>
                        </div>
                        <p className="warning-text"><Warning /> Warning: This number of stores should not sync simultaneously. Please contact support.</p>
                    </div>
                )}
            </div>
        </div>
    );
}
