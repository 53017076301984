import { Collection } from "../../../../types";

type Props =
{
    category: Collection
}

export default function StoreReportCategoriesItem({ category }: Props) {
    return (
        <div className="list-item">
            <div className="item-line">
                <div className="item-column nameItem">{category.title ? category.title  : 'Not available'}</div>
                <div className="item-column idItemRs">{category.rsId ? category.rsId  : 'Not available'}</div>
                <div className="item-column shopifyItem">{category.shopifyId ? category.shopifyId  : 'Not available'}</div>
                <div className="item-column productItem">{category.shopifyProductHandle ? category.shopifyProductHandle  : 'Not available'}</div>
                <div className="item-column elasticItem">{category.elasticSearchId ? category.elasticSearchId  : 'Not available'}</div>
                <div className="item-column mirrorItem">{category.isInMirror ? 'YES' : 'NO'}</div>
                <div className="item-column inShopifyItem">{category.isInShopify ? 'YES': 'NO'}</div>
                <div className="item-column inShopifyItem">{category.isInIndex ? 'YES' : 'NO'}</div>
            </div>
        </div>
    )
}