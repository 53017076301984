import { ProductCategory } from "../../../../types";

type Props =
{
    productCategory: ProductCategory
}

export default function StoreReportProductCategoryItem({ productCategory }: Props) {
    return (
        <div className="list-item">
            <div className="item-line">
                <div className="item-column catProdName">{productCategory.productName ? productCategory.productName  : 'Not available'}</div>
                <div className="item-column catProdID">{productCategory.productId ? productCategory.productId  : 'Not available'}</div>
                <div className="item-column catProdCatName">{productCategory.categoryName ? productCategory.categoryName  : 'Not available'}</div>
                <div className="item-column catProdCatID">{productCategory.categoryId ? productCategory.categoryId  : 'Not available'}</div>
            </div>
        </div>
    )
}