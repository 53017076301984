import { useEffect, useState } from "react";
import DateIcon from "../../../assets/DateIcon";
import Arrow from "../../../assets/Arrow";
import { Refresh } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

type Props =
{
  updateStores: () => void
  search: string
  updateSearch: (value: string) => void
}

export default function StoresListHeader ({ updateStores, search, updateSearch }: Props) 
{
  const stores_target = process.env.REACT_APP_TARGET_NAME;
  const [activeAutoRefresh, setActiveAutoRefresh] = useState<string | null>(
    localStorage.getItem('auto-refresh') === 'active' ? 'active' : 'inactive'
  );
  const [refreshMinutes, setRefreshMinutes] = useState<string | null>(
    localStorage.getItem('refresh-minutes') || '1'
  );
  
  const navigate = useNavigate();

  const padTo2Digits: (num: number) => string = (num: number) => 
  {
    return num.toString().padStart(2, '0');
  }

  const formatDate: (date: Date) => string = (date: Date) => 
  {
    return (
      [
        date.getUTCFullYear(),
        padTo2Digits(date.getUTCMonth() + 1),
        padTo2Digits(date.getUTCDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getUTCHours()),
        padTo2Digits(date.getUTCMinutes()),
        padTo2Digits(date.getUTCSeconds()),
      ].join(':')
    );
  }

  const [lastRefreshTime, setLastRefreshTime] = useState<string>(
    localStorage.getItem('time-zone') === 'active'
      ? (new Date()).toLocaleString()
      : formatDate(new Date())
  );

  const navigateToCreate: () => void = () => {
    navigate(`/create`, { replace: true });
  }

  const handleChange: (event: { target: { value: any } }) => void = (event: { target: { value: any; }; }) => 
  {
    const storeSearch = event.target.value.toLocaleLowerCase();
    updateSearch(storeSearch);

    if (storeSearch.length === 0)
      updateStores();
  }

  const update: () => void = () => 
  {
    updateStores();
    setLastRefreshTime(localStorage.getItem('time-zone') === 'active'
      ? (new Date()).toLocaleString()
      : formatDate(new Date()));
  }

  useEffect(() => {
    if(refreshMinutes != null && activeAutoRefresh != null){
      if(refreshMinutes == '-1')
        setActiveAutoRefresh('inactive');
      else
        setActiveAutoRefresh('active');
      localStorage.setItem('auto-refresh', activeAutoRefresh);
      localStorage.setItem('refresh-minutes', refreshMinutes);
      if (activeAutoRefresh === 'active' && refreshMinutes != null && refreshMinutes != '-1') {
        const intervalId = setInterval(() => {
          update();
        }, Number.parseInt(refreshMinutes) * 60 * 1000);
    
        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [activeAutoRefresh, refreshMinutes, search]);

  return (
    <div className="admin-portal-header-list">
      <div className="actionsBar row">
        <div className="bottomBar">         
          <div className="searchContainer">
            <input className={"admin-portal-search-input"} value={search} onChange={handleChange} placeholder="Search..."></input>
          </div>
          <div className="last-refresh-time-container">
            <div className="last-refresh-time-container-small">
              <div className="last-refresh-time-div row ">
                <span className="last-refresh-time-svg col-2">
                  <DateIcon></DateIcon>
                </span>
                <div className="col-10 last-refresh-time-div" data-tooltip-content="(UTC)">
                  <p className={'last-refresh-time-div'}>
                    {"Last refresh: "}
                  </p>
                  <span className="last-refresh-time-span">
                    {lastRefreshTime} {localStorage.getItem('time-zone') === 'active' ? 'Local' : 'UTC'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="refresh-container">
            <button onClick={() => update()}>
              <Refresh className='firstSvg'></Refresh> Refresh
              <div className="refresh-dropdown">
                <button className="refresh-button" onClick={(e) => e.stopPropagation()}><Arrow></Arrow></button>
                <div className="dropdown-content">
                  <label className='repeatEvery'>Repeat every:</label>
                    {[-1, 1, 2, 5, 15].map((value) => (
                    <label key={value}>
                    <input
                      type="radio"
                      name="minutes"
                      value={value.toString()}
                      checked={refreshMinutes === value.toString()}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => {
                        setRefreshMinutes(value.toString());
                        if (value === -1) {
                          setActiveAutoRefresh('inactive');
                        } else setActiveAutoRefresh('active');
                      }}
                    />
                    {value === -1 ? 'Disable auto-refresh' : `${value} minute${value !== 1 ? 's' : ''}`}
                  </label>
                ))}
                </div>
              </div>
            </button>
          </div>
          <div className="newStore-container">
          {(localStorage.getItem('role') == 'admin' || localStorage.getItem('role')=='sync_admin') && (
            <button onClick={navigateToCreate}>
              <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_2.png?v=1697806203"/>
              New Store
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
  )
}