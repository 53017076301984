import { Store, StoreDifferences } from "../../../types";
import NoImage from '../../../assets/NoImage.jpg';
import DateIcon from "../../../assets/DateIcon";
import website from '../../../assets/website.png';
import PlanIcon from "../../../assets/plan/PlanIcon";
import formatDateToLocal from "../../../utils/formatDateToLocal";

type Props =
    {
        store: Store
    }

const planNames: { [key: string]: string } = {
    '0': 'Unknown',
    '1': 'Bronze',
    '2': 'Silver',
    '3': 'Gold',
    '4': 'Platinum',
    '5': 'Kiosk',
    '6': 'Brochure',
};

export default function StoreReportHeader({ store }: Props) {
    const planName = planNames[store.storePlan] || 'Unknown';

    const getLogo: () => string = () => {
        if (store != undefined) {
            if (store.logo === null || store.logo === "" || store.logo === undefined) {
                return NoImage;
          } else {
              const image = new Image();
              image.src = store.logo;
    
              if (image.width > 0 && image.height > 0) {
                  return store.logo;
              } else {
                  return NoImage;
              }
          }
        } else {
          return NoImage;
        }
    };
      
    const getStoreUrl: () => string = () => {
        if (store.isMultiStore) return `https://${store.dns}.com`;
        return `https://${store.dns}`;
    };

    const handleGoBack = () => {
        window.location.href = '/stores';
    };

    const storeReport = store.storeReport ? JSON.parse(store.storeReport) : null;

    const productDifferences: StoreDifferences = {
        indexCount: storeReport?.Products.IndexCount || 0,
        shopifyCount: storeReport?.Products.ShopifyCount || 0,
        mirrorCount: storeReport?.Products.MirrorCount || 0,
        differences: storeReport?.Products.Differences || []
    };
    
    const collectionDifferences: StoreDifferences = {
        indexCount: storeReport?.Collections.IndexCount || 0,
        shopifyCount: storeReport?.Collections.ShopifyCount || 0,
        mirrorCount: storeReport?.Collections.MirrorCount || 0,
        differences: storeReport?.Collections.Differences || [],
    };

    return (
        <div className="store-report-container">
            <div>
                <button className="back-button" onClick={handleGoBack}>Back to dashboard</button>
            </div>
            <div className="store-info">
                <div className="one-col storeLogo">
                    <img src={getLogo()} alt={`logo-${store.friendlyName}`} id="logo-store" />
                </div>
                <div className="store-details">
                    <h1 className="report-title">{store.friendlyName}</h1>
                    <div className="containerPlan">
                        <p className="store-plan">
                            <span className="store-plan-span">
                                <PlanIcon type={planName} />
                            </span>
                            <span className={`store-plan-${planName}`}>
                                {planName}
                            </span>
                        </p>
                        <div className="website">
                            <img src={website} alt={``} />
                            <p className="title type">
                                <span>
                                    <a href={getStoreUrl()} target="blank">
                                        Website
                                    </a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="differences-div">
                        <p className="progress-title">Sync results:</p>
                        <div className="sync-results">
                            <div className="listed-differences">
                                <p className="progress-title blueTitle">PRODUCTS</p>
                                <div className="differences-container">
                                    <div className="column index">
                                        <p className="differences-column-title blackTitle">INDEX</p>
                                        <p className="differences-column-result">
                                            {productDifferences ? productDifferences.indexCount ? productDifferences.indexCount : 'na' : ' na'}
                                        </p>
                                    </div>
                                    <div className="column shopify">
                                        <p className="differences-column-title blackTitle">SHOPIFY</p>
                                        <p className="differences-column-result">
                                            {productDifferences ? productDifferences.shopifyCount ? productDifferences.shopifyCount : 'na' : ' na'}
                                        </p>
                                    </div>
                                    <div className="column system">
                                        <p className="differences-column-title blackTitle">RETAILSYS</p>
                                        <p className="differences-column-result">
                                            {productDifferences ? productDifferences.mirrorCount ? productDifferences.mirrorCount : 'na' : ' na'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="listed-differences rightCol">
                                <p className="progress-title blueTitle">COLLECTIONS</p>
                                <div className="differences-container">
                                    <div className="column shopify">
                                        <p className="differences-column-title blackTitle">SHOPIFY</p>
                                        <p className="differences-column-result">
                                            {collectionDifferences ? collectionDifferences.shopifyCount ? collectionDifferences.shopifyCount : 'na' : ' na'}
                                        </p>
                                    </div>
                                    <div className="column system">
                                        <p className="differences-column-title blackTitle">RETAILSYS </p>
                                        <p className="differences-column-result">
                                        {collectionDifferences ? collectionDifferences.mirrorCount ? collectionDifferences.mirrorCount : 'na' : ' na'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="last-refresh-time">
                    <div className="last-refresh-time-div row ">
                        <span className="last-refresh-time-svg col-2">
                            <DateIcon></DateIcon>
                        </span>
                        <div className="col-10 last-refresh-time-div" data-tooltip-content="(UTC)">
                            <p className={'last-refresh-time-div'}>
                                {"Last refresh: "}
                            </p>
                            <span className="last-refresh-time-span">
                            {store.lastUpdate !== undefined ? (
                                <span>
                                    {localStorage.getItem('time-zone') === 'active'
                                        ? (store.lastUpdate
                                            ? `${formatDateToLocal(store.lastUpdate).replace(/T/, ' ').replace(/\..+/, '')} (Local)`
                                            : 'Not Available (Local)'
                                        )
                                        : (store.lastUpdate
                                            ? `${store.lastUpdate.replace(/T/, ' ').replace(/\..+/, '')} (UTC)`
                                            : 'Not Available (UTC)'
                                        )
                                    }
                                </span>
                            ) : (
                                <span>Not available at this moment</span>
                            )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}