import { Store, StoreShopifySyncInfo } from "../../../types";
import Divider from '@mui/material/Divider';
import {useState, useEffect } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { editShopifyHydrogenInfo } from "../../../lib/admin";
import StoreShopifySaveModal from "./StoreShopifySaveModal";

type Props =
{
    store: Store,
    hasUnsavedChanges: boolean,
    setHasUnsavedChanges: (flag: boolean) => void,
    hasBeenUpdated: boolean,
    setHasBeenUpdated: (flag: boolean) => void
}

export default function StoreHydrogenForm ({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) 
{
    const [showSecret, setShowSecret] = useState(store.hydrogenApiSecret ? false : true);
    const [showToken, setShowToken] = useState(store.hydrogenStorefrontToken ? false : true);
    const [showAdminToken, setShowAdminToken] = useState(store.hydrogenAdminToken ? false : true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const [updatedStore, setUpdatedStore] = useState<undefined | StoreShopifySyncInfo>(undefined);
    const [secretKey, setSecretKey] = useState(store.hydrogenApiSecret != undefined ? store.hydrogenApiSecret : "Not available");
    const [token, setToken] = useState(store.hydrogenStorefrontToken != undefined || store.hydrogenStorefrontToken != "null" ? store.hydrogenStorefrontToken : "Not available");
    const [key, setKey] = useState(store.hydrogenApiKey != undefined ? store.hydrogenApiKey : "Not available");
    const [adminToken, setAdminToken] = useState(store.hydrogenAdminToken != undefined || store.hydrogenAdminToken != 'null' ? store.hydrogenAdminToken : "Not available");
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleShowInfo = (field: 'syncSecret' | 'syncToken' | 'adminToken') => () =>{
        if (field === 'syncSecret') {
          setShowSecret(!showSecret);
        } else if (field === 'syncToken') {
          setShowToken(!showToken);
        } else if (field === 'adminToken') {
          setShowAdminToken(!showAdminToken);
        }
    };

    const handleInputChange = () => {
        setHasUnsavedChanges(true);
    };
    const resetChanges = () => {
        setHasUnsavedChanges(false);
    }

    const setChanges = () => {
        setModalOpen(false);
        const editedStore: StoreShopifySyncInfo = {
            id: store.id,
            apiSecretKey: store.hydrogenApiSecret,
            apiToken: store.hydrogenStorefrontToken,
            apiKey: store.hydrogenApiKey,
            apiAdminToken: store.hydrogenAdminToken,
        }
        if (secretKey !== store.hydrogenApiSecret && secretKey.trim() !== "" && secretKey!='Not available') editedStore.apiSecretKey = secretKey;
        if (token !== store.hydrogenStorefrontToken && token.trim() !== "" && token!='Not available') editedStore.apiToken = token;
        if (key !== store.hydrogenApiKey && key.trim() !== "" && key!='Not available') editedStore.apiKey = key;
        if (adminToken !== store.hydrogenAdminToken && token.trim() !== "" && token!='Not available') editedStore.apiAdminToken = token;
        setError(false);
        setUpdatedStore(editedStore);
        updateStore();
    }

    const updateStore = async () => {
        if(updatedStore==undefined) return;
        const response = await editShopifyHydrogenInfo(updatedStore);
        
        if (response) {
            setSuccess(true);
            setError(false);
            setActionMessage("Changes saved successfully.");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } else {
            setSuccess(false);
            setError(true);
            setActionMessage("An error occurred. Please try again later.");
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    }

    useEffect(()=>{
        updatedStore && updateStore();
    },[updatedStore])

    return(
        <div className="sync-management">
            {modalOpen && (
                <StoreShopifySaveModal onSave={setChanges} onCancel={closeModal}/>
            )}
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1> <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/hidro.png?v=1696262901" alt="" />Shopify Hydrogen</h1>
            </div>
            <Divider className="divider"/>
            <div className="form-container">
                <div className="column">
                    <div className="field">
                        <p className="field-label">Application Client Secret</p>
                        <div className="field-edit">
                            <input 
                                type={secretKey == "Not available" ? 'text' : showSecret ? 'text' : 'password'}
                                id="syncSecret"
                                defaultValue={secretKey}
                                onChange={(e) => {
                                    handleInputChange();
                                    setSecretKey(e.target.value);
                                }} />
                            <button type="button" id="syncSecret" onClick={handleShowInfo('syncSecret')}>
                                {!showSecret ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Application Client Key</p>
                        <div className="field-edit">
                        <input 
                            type="text" 
                            defaultValue={key}
                            onChange={(e) => {
                                handleInputChange();
                                setKey(e.target.value);
                            }}/>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Admin API Token</p>
                        <div className="field-edit">
                            <input 
                                type={adminToken == "Not available" ? 'text' : showAdminToken ? 'text' : 'password'}
                                id="adminToken" 
                                defaultValue={adminToken}
                                onChange={(e) => {
                                    handleInputChange();
                                    setAdminToken(e.target.value);
                                }} />
                            <button type="button" id="adminToken" onClick={handleShowInfo('adminToken')}>
                                {!showAdminToken ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Storefront API Token</p>
                        <div className="field-edit">
                            <input 
                                type={token == "Not available" ? 'text' : showToken ? 'text' : 'password'}
                                id="syncToken" 
                                defaultValue={token}
                                onChange={(e) => {
                                    handleInputChange();
                                    setToken(e.target.value);
                                }} />
                            <button type="button" id="syncToken" onClick={handleShowInfo('syncToken')}>
                                {!showToken ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="error-container">
                    {error ? (
                        <span className="error-message">{actionMessage}</span>
                    ) : success ? (
                        <span className="success-message">{actionMessage}</span>
                    ) : null}
                </div>
                <div className="button-container">
                    <button className="edit-button" onClick={resetChanges}>
                        Cancel
                    </button>
                    <button
                        className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                        disabled={!hasUnsavedChanges}
                        onClick={()=>setModalOpen(true)}
                        >
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    );
}