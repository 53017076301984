type Props =
{
    onSave: () => void,
    onCancel: () => void,
}

export default function StoreShopifySaveModal ({ onSave, onCancel }: Props) 
{
    return(
        <div className="modal-save">
            <div className="modal-save-content">
                <p>Are you sure? If data is not correct, synchronization will not work for this store</p>
                <div className="modal-save-buttons">
                    <button className={`edit-button save`} onClick={onSave}>Save</button>
                    <button className={"edit-button"} onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}