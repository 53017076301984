import { Skeleton } from "@mui/material";

export default function StoreCardContainer() {

    return (
        <div className="store-date">
            <div className="info-stores-container-top">
                <div className="basic-dates-store topArea row">

                    <div className="col-s3 storeLogoContainer col-2">
                        <div className="one-col storeLogo">
                            <Skeleton variant="rectangular" width={75} height={74} />
                        </div>
                    </div>

                    <div className="col-s10 datesStoreContainer col-4">
                        <div className="websiteContainer">
                            <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
                        </div>
                        <div className="storeName">
                            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                        </div>
                        <Skeleton animation="wave" height={10} width="50%" style={{ marginTop: 40 }} />

                    </div>

                    <div className="last-refresh-time-container-small col-6">
                        <div className="last-refresh-time-div row ">
                            <div className="col-10 last-refresh-time-div" data-tooltip-content="(UTC)">
                                <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
                                <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />

                            </div>
                        </div>
                        <div className="last-refresh-time-div row ">
                            <div className="col-10 last-refresh-time-div" data-tooltip-content="(UTC)">
                                <Skeleton animation="wave" height={10} width="50%" style={{ marginBottom: 6 }} />
                                <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />

                            </div>
                        </div>
                    </div>
                </div>
                <Skeleton variant="rounded" width="100%" height={90} style={{ marginTop: 15 }} />
                <br></br>
                <Skeleton variant="rounded" width="100%" height={90} />
            </div>
        </div>
    )
}