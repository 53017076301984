import { useEffect, useState } from "react";
import Filters from "../../../assets/Filters";
import Arrow from "../../../assets/Arrow";
import Close from "../../../assets/Close";

type Props =
  {
    storeType: string
    setStorePlan: (value: number) => void
    setNewStatus: (value: number) => void
    setMultiStore: (value: boolean) => void
    setSynchronizing: (value: boolean) => void
    setOrderBy: (value: string) => void
    setClearFilters: (value: boolean) => void
    handleViewChange: () => void
  }

const plans = [
  { value: '-1', label: 'All stores' },
  { value: '0', label: 'Unknown' },
  { value: '1', label: 'Bronze' },
  { value: '2', label: 'Silver' },
  { value: '3', label: 'Gold' },
  { value: '4', label: 'Platinum' },
  { value: '5', label: 'Kiosk' },
  { value: '6', label: 'Brochure' },
];

const wsPlanOptions = [
  { value: '-1', label: 'All stores' },
  { value: '1', label: 'Bronze' },
  { value: '2', label: 'Silver' },
  { value: '3', label: 'Gold' },
  { value: '4', label: 'Platinum' },
];

const kbPlanOptions = [
  { value: '-1', label: 'All stores' },
  { value: '5', label: 'Kiosk' },
  { value: '6', label: 'Brochure' },
];

const statusOptions = [
  { value: '-1', label: 'All stores' },
  { value: '0', label: 'Offline' },
  { value: '1', label: 'In progress' },
  { value: '2', label: 'Live' },
];

export default function StoresListFilterMenu({ storeType, setStorePlan, setNewStatus, setMultiStore, setSynchronizing, setOrderBy, setClearFilters, handleViewChange }: Props) {
  const [plan, setPlan] = useState<string>("-1");
  const [status, setStatus] = useState<string>("-1");
  const [isMultiStore, setIsMultiStore] = useState<boolean>(false);
  const [isSynchronizing, setIsSynchronizing] = useState<boolean>(false);
  const [orderBy, setOrderListBy] = useState<string>("default");
  const [activeListView, setActiveListView ] = useState<boolean>(false);
  const [planOptions, setPlanOptions] = useState(plans);

  const handleSaveChanges = () => {
    setStorePlan(parseInt(plan));
    setNewStatus(parseInt(status));
    setMultiStore(isMultiStore);
    setSynchronizing(isSynchronizing);
  }

  const handleClearFilters = () => {
    setPlan("-1");
    setStatus("-1");
    setIsMultiStore(false);
    setIsSynchronizing(false);
    setOrderListBy("default");
    setClearFilters(true);
  }

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPlan(event.target.value);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value);
  };

  const handleIsMultiStoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMultiStore(event.target.checked);
  };

  const handleSynchronizingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSynchronizing(event.target.checked);
  };

  const handleOrderBy = (value: string) => {
    setOrderListBy(value);
  }

  useEffect(() => {
    setOrderBy(orderBy);
  }, [orderBy]);

  useEffect(() => {
    setPlanOptions(getPlanOptions(storeType));
    setPlan("-1");
    setStatus("-1");
    setIsMultiStore(false);
    setIsSynchronizing(false);
  }, [storeType]);

  const getPlanOptions = (storeType: string) => {
    switch (storeType) {
      case 'ws':
        return wsPlanOptions;
      case 'kb':
        return kbPlanOptions;
      default:
        return plans;
    }
  };

  const [isSliderVisible, setSliderVisible] = useState(false);

  const toggleSlider = () => {
    setSliderVisible(!isSliderVisible);
  };

  const changeView = (option: number) => {
    if(option == 1){
      if(activeListView){
        handleViewChange();
        setActiveListView(false);
      }
    }
    if(option == 2){
      if(!activeListView){
        handleViewChange();
        setActiveListView(true);
      }
    }
  };

  const getOrderByDescription = (value: string) => {
    switch (value) {
      case "default":
        return "Default order";
      case "name-asc":
        return "A-Z";
      case "name-desc":
        return "Z-A";
      case "last-sync-desc":
        return "Oldest update";
      case "last-sync-asc":
        return "Newest update";
      case "next-sync-desc":
        return "Sooner scheduled sync";
      case "next-sync-asc":
        return "Later scheduled sync";
      default:
        return value;
    }
  };

  return (
    <div className="filterBar">
      <div className="slider-menu" style={{ position: 'relative' }}>
        {isSliderVisible && (
          <div className="slider-menu-overlay">
            <div className="slider-menu lateral">
              <button onClick={toggleSlider} className="close-button">
                <Close></Close>
              </button>
              <div className="slider-parent">
                <h1>Filters</h1>
                <div className="plan-filter-div">
                  <p>Plan:</p>
                  <select
                    id="filter-plan"
                    name="filter-plan"
                    value={plan}
                    className="filter-dropdown"
                    onChange={(event) => handlePlanChange(event as React.ChangeEvent<HTMLSelectElement>)}
                  >
                    {planOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="plan-filter-div">
                  <p>Status:</p>
                  <select
                    id="filter-status"
                    name="filter-status"
                    value={status}
                    className="filter-dropdown"
                    onChange={(event) => handleStatusChange(event as React.ChangeEvent<HTMLSelectElement>)}
                  >
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="filters-div">
                  <p>Type:</p>
                  <label>
                    <input type="checkbox" checked={isMultiStore} onChange={handleIsMultiStoreChange} /> BigFurniture Store
                  </label>
                  <label>
                    <input type="checkbox" checked={isSynchronizing} onChange={handleSynchronizingChange} /> Currently synchronizing
                  </label>
                </div>
                <div className="apply-button-div">
                  <button className="ClearBtn" onClick={handleClearFilters}>
                    Clear
                  </button>
                  <button className="ApplyBtn" onClick={() => { handleSaveChanges(); toggleSlider(); }}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="menu-button-div">
          <div className="left-button">
            <button onClick={toggleSlider} className="filter-button" type="button">
              <Filters />
              Filters
            </button>
            <div className="dropdown-wrapper">
              <button className="filter-button" type="button" onClick={(e) => e.stopPropagation()}>
                Order by
                <Arrow />
              </button>
              <div className="dropdown-content">
                {["default", "name-asc", "name-desc", "last-sync-asc", "last-sync-desc", "next-sync-asc", "next-sync-desc"].map((value) => (
                  <label key={value}>
                    <input
                      type="radio"
                      name="orderBy"
                      value={orderBy}
                      checked={orderBy === value}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => {
                        handleOrderBy(value);
                      }}
                    />
                    {getOrderByDescription(value)}
                  </label>
                ))}
              </div>
            </div>
            <div className="right-button">
            <button className="filter-button cBtn" onClick={handleClearFilters}>
              Clear
            </button>
          </div>
          </div>
          
        </div>
      </div>
      <div className="viewMode">
        <div className="cardMode">
          <button className={activeListView ? "" : "activeMode"} onClick={() => {changeView(1)}}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="5" height="5" stroke="#77889F" />
              <rect x="8.5" y="0.5" width="5" height="5" stroke="#77889F" />
              <rect x="0.5" y="8.5" width="5" height="5" stroke="#77889F" />
              <rect x="8.5" y="8.5" width="5" height="5" stroke="#77889F" />
            </svg>
          </button>

        </div>
        <div className="listMode">
          <button className={activeListView ? "activeMode" : ""} onClick={() => {changeView(2)}}>
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="14" height="3" stroke="#77889F" />
              <rect x="0.5" y="5.5" width="14" height="3" stroke="#77889F" />
              <rect x="0.5" y="10.5" width="14" height="3" stroke="#77889F" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}