import { useState } from "react";

export function DateUTC ()
{
    const padTo2Digits = (num:number) => 
    {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date:Date) =>
    {
        return (
            [
              date.getUTCFullYear(),
              padTo2Digits(date.getUTCMonth() + 1),
              padTo2Digits(date.getUTCDate()),
            ].join('-') +
            ' ' +
            [
              padTo2Digits(date.getUTCHours()),
              padTo2Digits(date.getUTCMinutes()),
              padTo2Digits(date.getUTCSeconds()),
            ].join(':')
          );
    }

    const [date, setDate] = useState<string>(formatDate(new Date()));
    
    setInterval(() => {
        updateDate();
    }, 1000);

    const updateDate = () => 
    {
        setDate(formatDate(new Date()));
    }

    return (
        <div>
            <p className='admin-portal-date-utc-p' data-tooltip-content="(UTC)">
                <span className='admin-portal-date-utc-span' >
                    {date} (UTC)
                </span>
            </p>
        </div>
    )
}