import { Store } from "../../../types";
import NoImage from '../../../assets/NoImage.jpg';
import { useCallback, useEffect, useState } from "react";

type StoreWidgetCardProps = {
    store: Store;
};

export function StoreWidgetCard({ store }: StoreWidgetCardProps) {
    const [logoUrl, setLogoUrl] = useState<string>(NoImage);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadLogo = useCallback(() => {
        setIsLoading(true);
        setLogoUrl(NoImage);
      
        if (store.logo) {
          console.log(`Loading logo for ${store.friendlyName}:`, store.logo);
          const img = new Image();
          img.onload = () => {
            setLogoUrl(store.logo);
            setIsLoading(false);
          };
          img.onerror = () => {
            console.error(`Failed to load logo for ${store.friendlyName}`);
            setLogoUrl(NoImage);
            setIsLoading(false);
          };
          img.src = store.logo;
        } else {
          setIsLoading(false);
        }
    }, [store.logo]);

    useEffect(() => {
        loadLogo();
    }, [loadLogo]);

    const getWebSystemText = (): string => {
        const planTexts: { [key: number]: string } = {
            5: 'Kiosk',
            6: 'Brochure'
        };
        return planTexts[store.storePlan] || 'WebSystem';
    };

    return (
        <div className="sync-widget-card">
            {isLoading ? (
                <img src={NoImage} alt={`logo-${store.friendlyName}`} id="logo-store" />
            ) : (
                <img src={logoUrl} alt={`logo-${store.friendlyName}`} id="logo-store" />
            )}
            <div className="sync-widget-details">
                <p className="websystem-text">{getWebSystemText()}</p>
                <p className="store-widget-name">{store.friendlyName}</p>
            </div>
        </div>
    );
}
