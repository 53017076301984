import { AccountType, Store, StoreCSVInfo} from '../../../types';
import {useState, useEffect } from "react";
import { Divider } from '@mui/material';
import { editStoreCSVInfo, getCSVAccountData } from '../../../lib/admin';
import { StoreAccountDropdown } from './StoreAccountDropdown';

type Props =
{
    store: Store,
    hasUnsavedChanges: boolean,
    setHasUnsavedChanges: (flag: boolean) => void,
    hasBeenUpdated: boolean,
    setHasBeenUpdated: (flag: boolean) => void
}

export default function StoreCSVForm ({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) 
{
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const [updatedStore, setUpdatedStore] = useState<undefined | StoreCSVInfo>(undefined);
    const [accountId, setAccountId] = useState(store.csvAccountId != undefined ? store.csvAccountId : "Not available");
    const [csv, setCSV] = useState(store.csv != undefined ? store.csv : "Not available");
    const [accountOptions, setAccountOptions] = useState<AccountType[]>([]);

    const handleInputChange = () => {
        setHasUnsavedChanges(true);
    };

    const resetChanges = () => {
        setHasUnsavedChanges(false);
        setError(false);
        setSuccess(false);
        setCSV(store.csv != undefined ? store.csv : "Not available");
        setAccountId(store.csvAccountId != undefined ? store.csvAccountId : "Not available");
    }

    const onDeletedAccount = () => {
        setAccountId("none");
        setHasUnsavedChanges(true);
    }

    const onSelectedAccount = (id: number) => {
        if (accountOptions) {
          const accountSelected = accountOptions.find(account => account.id === id);
          if (accountSelected) {
            setAccountId(id);
            setHasUnsavedChanges(true);
          }
        }
    }

    const setChanges = () => {
        const editedStore: StoreCSVInfo = {
            id: store.id,
            csv: store.csv,
            csvAccountId: store.csvAccountId
        }
        if(accountId == "none")
            editedStore.csvAccountId = null;
        if (accountId !== store.csvAccountId && accountId !== "Not available" && accountId !== "") {
            const parsedAccountId = typeof accountId === "string" ? parseInt(accountId) : accountId;
            if (!isNaN(parsedAccountId)) editedStore.csvAccountId = parsedAccountId;  
        }
        if (csv.trim() !== "" && csv!='Not available') editedStore.csv = csv;
        else {
            setError(false);
            setActionMessage("Invalid values. Try again.");
            setError(true);
            return;
        }
        setSuccess(false);
        setError(false);
        setUpdatedStore(editedStore);
        updateStore();
    }

    const updateStore = async () => {
        if(updatedStore==undefined) return;
        const response = await editStoreCSVInfo(updatedStore);     
        if (response) {
            setSuccess(true);
            setError(false);
            setActionMessage("Changes saved successfully.");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } else {
            setSuccess(false);
            setError(true);
            setActionMessage("An error occurred. Please try again later.");
        }
    };

    useEffect(()=>{
        updatedStore && updateStore();
    },[updatedStore])

    useEffect(()=>{
        updateDropdown();
    }, [])

    const updateDropdown = async () => {
        const response = await getCSVAccountData();
        setAccountOptions(response);
    }

    return(
        <div className="sync-management">
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1>CSV Configuration</h1>
            </div>
            <Divider className="divider"/>
            <div className="form-container">
                <div className="column">
                    <div className="field">
                        <p className="field-label">This kiosk associated account:</p>
                        
                            <StoreAccountDropdown
                                defaultValue={store.csvAccountId}
                                accountOptions={accountOptions}
                                onSelectAccount={onSelectedAccount}
                                onDeletedAccount={onDeletedAccount}
                            />
                     
                    </div>
                    <div className="field">
                        <p className="field-label">CSV Name</p>
                        <div className="field-edit">
                        <input 
                            type="text" 
                            defaultValue={csv}
                            onChange={(e) => {
                                handleInputChange(); 
                                setCSV(e.target.value);
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="error-container">
                    {error ? (
                        <span className="error-message">{actionMessage}</span>
                    ) : success ? (
                        <span className="success-message">{actionMessage}</span>
                    ) : null}
                </div>
                <div className="button-container">
                    <button className="edit-button" onClick={resetChanges}>
                        Cancel
                    </button>
                    <button
                        className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                        disabled={!hasUnsavedChanges}
                        onClick={setChanges}
                        >
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    );
}