import { Store, SyncFilters } from "../../../types"
import { useState } from "react";
import Close from "../../../assets/Close"
import NoImage from '../../../assets/NoImage.jpg';
import { syncCall, syncStoreWithFilters } from "../../../lib/admin";

type Props =
{
    store: Store,
    close: () => void,
    syncStore: () => void
}

export default function StoreCardSyncModal({store, close}: Props) 
{
    const [navigation, setNavigation] = useState(false);
    const [products, setProducts] = useState(false);
    const [categories, setCategories] = useState(false);
    const [brands, setBrands] = useState(false);
    const [ranges, setProductRanges] = useState(false);
    const [variants, setVariants] = useState(false);
    const [collections, setCollections] = useState(false);
    const [meta, setMeta] = useState(false);
    const [parser, setParser] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSyncFilters = async () => {
        if(!navigation&&!products&&!categories&&!brands&&!ranges&&!variants&&!collections&&!meta&&!parser){
            setError(true);
            setErrorMessage("Select at least one filter to sync.");
        }
        else{
            const body: SyncFilters = {
                navigation: navigation,
                products: products,
                categories: categories,
                brands: brands,
                ranges: ranges,
                variants: variants,
                collections: collections,
                metafields: meta,
                parser: parser
            }
            const response = await syncStoreWithFilters(body, store.id);
            if (response.ok) {
                setError(false);
                close();
            } else {
                const errorText = await response.text();
                setErrorMessage(errorText);
                setError(true);
            }
        }
    }

    return (
        <div className="sync-modal-overlay">
            <div className="sync-modal center">
                 <div className="synModal">
                    <div className="synModal__wrapper">
                        <div className="synModal__header">
                            <div className="synModal__close">
                                <button onClick={close}>Close <Close></Close></button>
                            </div>
                            <div className="synModal__title">
                                <h1>Entities to synchronize:</h1>
                            </div>
                        </div>
                        <div className="synModal__content">
                            <div className="synModal__greyArea">
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={navigation} onClick={()=>setNavigation(!navigation)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Navigation menu</p>
                                </div>                            
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={products} onClick={()=>setProducts(!products)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Products</p>
                                </div>
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={categories} onClick={()=>setCategories(!categories)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Categories</p>
                                </div>  
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={brands} onClick={()=>setBrands(!brands)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Brands</p>
                                </div>   
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={ranges} onClick={()=>setProductRanges(!ranges)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Product Ranges</p>
                                </div> 
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={variants} onClick={()=>setVariants(!variants)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Product Variants</p>
                                </div> 
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={collections} onClick={()=>setCollections(!collections)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Shopify Collections</p>
                                </div>
                                <div className="synModal__item">                                
                                    <label className="switch">
                                    <input type="checkbox" checked={meta} onClick={()=>setMeta(!meta)}/>
                                        <span className="slider round"></span>
                                        </label>
                                        <p>Metafields</p>
                                </div>                            
                                <div className="synModal__item">
                                    <label>
                                        <input type="checkbox" checked={parser} onClick={()=>setParser(!parser)}/> With Parser
                                    </label>
                                </div>
                                <div className="synModal__btnSync">
                                    <button className="edit-button save" onClick={handleSyncFilters}>Sync these filters</button>
                                </div>
                                <div className="error-sync">
                                    {error 
                                    ?
                                        <p>{errorMessage}</p>
                                    :
                                        <></>
                                    }
                                </div>
                            </div>
                            <div className="synModal__buttomArea">
                                <h2>OR YOU CAN:</h2>
                                <button className="edit-button syncAll" onClick={()=>syncCall}>Sync all</button>
                                <div className="synModal__alert">
                                    <p><img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/alert.png?v=1701881196" /> <span>WARNING!</span>  CHANGES CANNOT BE UNDONE</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}