export default function DateIcon () {
    return (
        <svg width="22" height="22" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_809_308)">
                <path d="M9.00384 18.716C4.05538 18.7198 0.00767699 14.6798 1.0892e-05 9.73128C-0.0076552 4.76748 4.03238 0.719778 8.99234 0.715945C13.9523 0.712112 18 4.75981 17.9962 9.71978C17.9923 14.6759 13.96 18.7083 9.00384 18.716ZM8.1874 7.24747C8.1874 8.06007 8.1874 8.86885 8.18357 9.68145C8.18357 9.91144 8.2449 10.1108 8.40589 10.2756C9.23382 11.1073 10.0656 11.9391 10.8974 12.7671C11.1082 12.974 11.3765 13.0315 11.6601 12.9549C12.2849 12.7862 12.4689 12.0426 12.0013 11.5711C11.319 10.8812 10.6329 10.1951 9.94294 9.5128C9.84328 9.41314 9.80495 9.31348 9.80878 9.17549C9.81261 7.75726 9.81261 6.33903 9.80878 4.9208C9.80878 4.81348 9.80878 4.70232 9.78195 4.59883C9.67462 4.19252 9.30282 3.95104 8.87735 4.00087C8.49405 4.04687 8.1874 4.38801 8.1874 4.79431C8.18357 5.61075 8.1874 6.43103 8.1874 7.24747Z" fill="black" fill-opacity="0.87"/>
            </g>
            <defs>
                <clipPath id="clip0_809_308">
                    <rect width="18" height="18" fill="white" transform="translate(0 0.715942)"/>
                </clipPath>
            </defs>
        </svg>
    )
}