import IconBrochure from "./IconBrochure"
import IconBronze from "./IconBronze"
import IconGold from "./IconGold"
import IconPlatinum from "./IconPlatinum"
import IconSilver from "./IconSilver"

export default function PlanIcon ({type}:{type:string}) 
{
    switch(type) 
    {
        case "Bronze": return <IconBronze/>
        case "Silver": return <IconSilver/>
        case "Gold": return <IconGold/>
        case "Platinum": return <IconPlatinum/>
        case "Brochure": return <IconBrochure />
        default: <IconPlatinum/>
    }
    return <IconPlatinum/>
}