import React, { CSSProperties } from 'react';
import { secondaryColorDefaultProps, SecondaryColorSpinnerProps } from './helpers';
import { SpinnersProps, withSharedProps } from './withSharedProps';

import './SpinnerCircularFixed.css';

export type SpinnerCircularFixedProps = SpinnersProps & SecondaryColorSpinnerProps;

const Component = ({
  secondaryColor,
  speed,
  still,
  thickness,
  ...svgProps
}: SecondaryColorSpinnerProps) => {
  const strokeWidth = thickness !== undefined ? 4 * (thickness / 100) : 4 * (120 / 100);
  const circleStyle: CSSProperties = !still
    ? { animation: `spinners-react-circular-fixed ${ speed !== undefined ? 140 / speed : 140 / 1}s linear infinite` }
    : {};

  return (
    <svg fill="none" {...svgProps} viewBox="0 0 66 66">
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke="#f0f3f9"
        strokeWidth={strokeWidth + 2}
      />
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke="#138EFF" 
        strokeDasharray="40, 134"
        strokeDashoffset="325"
        strokeLinecap="round"
        strokeWidth={strokeWidth + 4}
        style={circleStyle}
      />
    </svg>
  );
};

Component.defaultProps = secondaryColorDefaultProps;

export const SpinnerCircularFixed = withSharedProps(Component);