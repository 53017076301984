import {useState, useEffect } from "react";
import { Layout } from "../global/Layout";
import { useParams } from "react-router-dom";
import LoginForm from "./LoginForm";

export default function LoginContainer() {
    return (
        <Layout>
            <LoginForm></LoginForm>
        </Layout>
    )   
}