import { ChangeEvent, useEffect, useState } from "react";
import { Store, StoreShopifyInfo } from "../../../types";
import Divider from '@mui/material/Divider';
import { editStoreShopify } from "../../../lib/admin";

type Props =
   {
      store: Store,
      hasUnsavedChanges: boolean,
      setHasUnsavedChanges: (flag: boolean) => void,
      hasBeenUpdated: boolean,
      setHasBeenUpdated: (flag: boolean) => void
   }

export default function StoreShopifyForm({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) {

   const [error, setError] = useState(false);
   const [success, setSuccess] = useState(false);
   const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
   const [storeChanges, setStoreChanges] = useState<undefined | StoreShopifyInfo>(undefined);
   const [body, setBody] = useState({ id: store.id, storeUrl: store.storeUrl, dns: store.dns, orderWebhookId: store.orderWebhookId })
   const [tempBody, setTempBody] = useState(body);

   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setTempBody({ ...tempBody, [name]: value });
      setHasUnsavedChanges(true);
   };

   const setChanges = () => {
      if (tempBody.storeUrl.trim() === "" ||  !tempBody.storeUrl.trim().endsWith("myshopify.com"))  {
         setError(true);
         setSuccess(false);
         setActionMessage("Select a valid Shopify store Url");
         return;
      }
      
      const updatedStoreChanges: StoreShopifyInfo = {
         id: tempBody.id,
         storeurl: tempBody.storeUrl ? tempBody.storeUrl.trim() : store.storeUrl,
         orderwebhookid: tempBody.orderWebhookId ? tempBody.orderWebhookId.trim() : "",
      };
      setError(false);
      setStoreChanges(updatedStoreChanges);
      setHasUnsavedChanges(false);
   }

   useEffect(()=>{
      storeChanges && sendStores();
  },[storeChanges])



   const sendStores = async () => {
      if (storeChanges == undefined) return;
      const response = await editStoreShopify(storeChanges);

      if (response) {
         setSuccess(true);
         setError(false);
         setActionMessage("Changes saved successfully.");
         setHasUnsavedChanges(false);
         setHasBeenUpdated(true);
         setBody(tempBody);
      } else {
         setSuccess(false);
         setError(true);
         setActionMessage("An error occurred. Please try again later.");
      }
   }

   const resetForm = () => {
      setError(false);
      setSuccess(false);
      setActionMessage("An error occurred. Please try again later.");
      setHasUnsavedChanges(false);
      setTempBody(body);
   };

   return (
      <div className="sync-management">

         <div className="sync-header">
            <p>Shopify Stores/{store.friendlyName}</p>
            <h1> <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/shopy.png?v=1696262901" alt="" />Shopify</h1>
         </div>
         <Divider className="divider" />
         <div className="form">

            <div className="field">
               <label htmlFor="shopifyUrl" className="field-label">Shopify Store URL</label>
               <input type="text" id="shopifyUrl" placeholder="" className="field-input" name="storeUrl" value={tempBody.storeUrl} onChange={handleChange} />
            </div>
            <div className="field">
               <label htmlFor="orderWebHookId" className="field-label">Order WebHookId</label>
               <input type="text" id="orderWebHookId" className="field-input" name="orderWebhookId" value={tempBody.orderWebhookId} onChange={handleChange} />
            </div>
            <Divider className="divider" />
            <div className="footer">
               <div className="error-container">
                  {error ? (
                     <span className="error-message">{actionMessage}</span>
                  ) : success ? (
                     <span className="success-message">{actionMessage}</span>
                  ) : null}
               </div>
               <div className="button-container">
                  <div>
                     <button className="edit-button" onClick={resetForm}>
                        Cancel
                     </button>
                     <button
                        className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                        onClick={setChanges}
                        disabled={!hasUnsavedChanges}
                     >
                        Save changes
                     </button>
                  </div>

               </div>
            </div>


         </div>
      </div>
   );
}
