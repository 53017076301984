import { Collection, Store } from "../../../../types";
import { useState, useEffect } from "react";
import { getAllProductRanges } from "../../../../lib/admin";
import StoreReportCategoriesHeader from "../categories/StoreReportCategoriesHeader";
import StoreReportCategoriesItem from "../categories/StoreReportCategoriesItem";
import StoreReportProductFilterMenu from "../products/StoreReportProductFilterMenu";
import StoreReportPagination from "../StoreReportPagination";
import { SpinnerCircularFixed } from "../../../../assets/spinner/SpinnerCircularFixed";

type Props =
{
    store: Store
}

export default function StoreReportRangesList({ store }: Props) {
    const [isLoading, setLoading] = useState(true);
    const [ranges, setRanges] = useState<undefined | Collection[]>(undefined);
    const [filteredRanges, setFilteredRanges] = useState<undefined | Collection[]>(undefined); 
    const [filters, setFilters] = useState({
        inMirror: false,
        inShopify: false,
        onIndex: false,
        notInMirror: false,
        notInShopify: false,
        notOnIndex: false
    });
    const [orderBy, setOrderBy] = useState<string>('default');
    const [clearFilter, setClearFilter] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [retrieveError, setRetrieveError] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState(1);
    const brandsPerPage = 25;
    const indexOfLastBrand = currentPage * brandsPerPage;
    const indexOfFirstBrand = indexOfLastBrand - brandsPerPage;
    
    const currentBrands = filteredRanges != undefined ? filteredRanges.slice(indexOfFirstBrand, indexOfLastBrand) : ranges?.slice(indexOfFirstBrand, indexOfLastBrand);
    const totalBrands = filteredRanges !== undefined ? filteredRanges.length : ranges?.length;
    const currentBrandsCount = currentBrands?.length || 0;
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const updateSearch: (value: string) => void = (value: string) => {
        setSearch(value);
    }

    const searchRange: () => void = () => {
        if (search.length >= 2 && ranges !== undefined && ranges?.length > 0) {
            if(!filters.inMirror || !filters.inShopify || !filters.onIndex || !filters.notInMirror || !filters.notInShopify || !filters.notOnIndex || orderBy !== 'default'){
                const filteredRanges = handleFilters();
                const brandsFind = filteredRanges?.filter(c => c.title?.toLocaleLowerCase().match(search.toLocaleLowerCase()));
                setFilteredRanges(brandsFind ?? []);
            }else{
                const brandsFind = ranges?.filter(c => c.title?.toLocaleLowerCase().match(search.toLocaleLowerCase()));
                setFilteredRanges(brandsFind ?? []);
            }
        }
    }

    useEffect(() => {
        if(search != "")
            searchRange();
        else
            updateBrands();
    }, [search]);

    const handleFilters = () => {
        if(ranges != undefined){
            let filteredRange = [...ranges];
            if(filteredRange !== undefined){
                if (filters.inShopify)
                    filteredRange = filteredRange.filter(p => p.isInShopify);
                if (filters.inMirror)
                    filteredRange = filteredRange.filter(p => p.isInMirror);
                if (filters.onIndex)
                    filteredRange = filteredRange.filter(p => p.isInIndex);
                if (filters.notInShopify)
                    filteredRange = filteredRange.filter(p => !p.isInShopify);
                if(filters.notInMirror)
                    filteredRange = filteredRange.filter(p => !p.isInMirror);
                if (filters.notOnIndex)
                    filteredRange = filteredRange.filter(p => !p.isInIndex);
                if (orderBy === "name-asc")
                    filteredRange = filteredRange.sort((a, b) => a.title.localeCompare(b.title));
                else if (orderBy === "name-desc")
                    filteredRange = filteredRange.sort((a, b) =>  b.title.localeCompare(a.title));
            }
            return filteredRange;
        }
    };

    useEffect(() => {
        if(!clearFilter){
            if(!filters.inShopify && !filters.inMirror && !filters.onIndex 
                && !filters.notInMirror && !filters.notInShopify && !filters.notOnIndex && orderBy === 'default') {
                if(filteredRanges != undefined){
                    updateBrands();
                } 
            }else{
                if(search == ""){
                    const brandsFiltered = handleFilters();
                    setFilteredRanges(brandsFiltered);
                }
                else
                    searchRange();
            }
        }
    }, [filters, orderBy]);

    useEffect(() => { 
        if(clearFilter){
            setFilteredRanges(undefined);
            setSearch("");
            setFilters({
                onIndex: false,
                inMirror: false,
                inShopify: false,
                notInMirror: false,
                notInShopify: false,
                notOnIndex: false
            });
            setClearFilter(false);
        }
    }, [clearFilter]);

    const updateBrands = async () => {
        setLoading(true);
        setFilteredRanges(undefined);
        setRanges([]);
        try{
            const newBrands = await getAllProductRanges(store.id);
            setRanges(newBrands);
            setLoading(false);
        }
        catch {
            setRetrieveError(true);
            setLoading(false);
        }
    }

    const updateShopify = (inShopify: boolean) => {
        if(inShopify)
        setFilters(prevFilters => ({
          ...prevFilters,
          inShopify: inShopify,
          notInShopify: !inShopify
        }));
        else
        setFilters(prevFilters => ({
            ...prevFilters,
            inShopify: inShopify
        }));  
    }

    const updateMirror = (inMirror: boolean) => {
        if(inMirror)
        setFilters(prevFilters => ({
          ...prevFilters,
          inMirror: inMirror,
          notInMirror: !inMirror
        }));
        else
        setFilters(prevFilters => ({
            ...prevFilters,
            inMirror: inMirror
        }));  
    }

    const updateIndex = (onIndex: boolean) => {
        if(onIndex)
        setFilters(prevFilters => ({
          ...prevFilters,
          onIndex: onIndex,
          notOnIndex: !onIndex
        }));
        else
        setFilters(prevFilters => ({
            ...prevFilters,
            onIndex: onIndex
        }));  
    }

    const updateNotShopify = (inShopify: boolean) => {
        if(inShopify)
        setFilters(prevFilters => ({
          ...prevFilters,
          notInShopify: inShopify,
          inShopify: !inShopify
        }));
        else
        setFilters(prevFilters => ({
            ...prevFilters,
            notInShopify: inShopify
        }));  
    }

    const updateNotMirror = (inMirror: boolean) => {
        if(inMirror)
        setFilters(prevFilters => ({
          ...prevFilters,
          notInMirror: inMirror,
          inMirror: !inMirror
        }));
        else
        setFilters(prevFilters => ({
            ...prevFilters,
            notInMirror: inMirror
        }));  
    }

    const updateNotIndex = (onIndex: boolean) => {
        if(onIndex)
        setFilters(prevFilters => ({
          ...prevFilters,
          notOnIndex: onIndex,
          onIndex: !onIndex
        }));
        else
        setFilters(prevFilters => ({
            ...prevFilters,
            notOnIndex: onIndex
        }));  
    }

    const updateOrderBy: (value: string) => void = (value: string) => {
        setOrderBy(value);
    }

    useEffect(() => { 
        if(isLoading){
            updateBrands();
        }
    }, []);

    return(
        <div>
            {!retrieveError ? (
                <>
                    <StoreReportProductFilterMenu 
                        setInShopify={updateShopify} 
                        setInMirror={updateMirror} 
                        setOnIndex={updateIndex} 
                        setNotInShopify={updateNotShopify} 
                        setNotInMirror={updateNotMirror} 
                        setNotOnIndex={updateNotIndex} 
                        setOrderBy={updateOrderBy} 
                        setClearFilters={setClearFilter} 
                        setSearch={updateSearch}
                    />
                    <StoreReportCategoriesHeader/>
                </>
            ) : <></>}
            {!isLoading ? (
                ranges !== undefined && !retrieveError ? (
                    <div>
                        {currentBrands?.map((brand, i) => (
                            <div key={i} className="products-list-view">
                                <StoreReportCategoriesItem category={brand}></StoreReportCategoriesItem>
                            </div>
                        ))}
    
                        <StoreReportPagination
                            productsPerPage={brandsPerPage}
                            totalProducts={totalBrands}
                            paginate={setCurrentPage}
                            currentPage={currentPage}
                            currentProductsCount={currentBrandsCount}
                        />
                    </div>
                ) : retrieveError ? 
                    (
                        <p className="sync-error-retrieve">{store.syncState == 1 || store.syncV2IsRunning === 1 ? "The store's sync is currently in progress." : "Currently unable to retrieve the information."} Please try again later.</p>
                    )
                : (
                    <p className="no-products-message">No ranges available to display</p>
                )
            ) : (
                <div className="spinner-div">
                    <SpinnerCircularFixed size={35} thickness={140} speed={120} color="rgba(203, 216, 234, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                </div>
            )}
        </div>
    )
}