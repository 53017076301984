export default function StoreReportProductCategoryHeader() {
    return (
        <div className="list-item headerItems">
            <div className="item-line">
                <div className="item-column header catProdName">Product Name</div>
                <div className="item-column header catProdID">Product ID</div>
                <div className="item-column header catProdCatName">Category Name</div>
                <div className="item-column header catProdCatID">Category ID</div>
            </div>
        </div>
    )
}