type Props = {
    setProceed: (value: boolean) => void;
}

export default function StoreReportProceedMessage({ setProceed }: Props) {
    const handleProceed = () => {
        setProceed(true);
    }

    return (
        <div className="proceed-msg">
            <p>The time to generate this report may vary, potentially taking a few minutes.
                <br/>
                Would you like to proceed?
            </p>
            <input type="button" value={"Continue"} onClick={handleProceed}></input>
        </div>
    )
}
