import ALL from '../../assets/types/ALL.png';
import WS from '../../assets/types/WS.png';
import KB from '../../assets/types/KB.png';
import React, { useState } from 'react';
import { Store } from '../../types';
import StoresListSyncWidget from '../stores/list/StoreListSyncWidget';
import Syncz from '../../assets/Syncz';
import Arrow from '../../assets/Arrow';
import IconShopify from '../../assets/IconShopify';
import { Tooltip } from '@mui/material';

interface SideBarProps {
    onStoreTypeChange: (newType: 'all' | 'ws' | 'kb') => void;
    syncingStores: Store[] | undefined;
    isLoading: boolean;
}

export function SideBar({ onStoreTypeChange, syncingStores, isLoading }: SideBarProps) {
    const [isOpen, setIsOpen] = useState(true);
    const [selectedType, setSelectedType] = useState(() => {
        return localStorage.getItem('selectedStoreType') || 'all';
    });

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleTypeSelect = (type: any) => {
        setSelectedType(type);
        localStorage.setItem('selectedStoreType', type);
        onStoreTypeChange(type);
    };

    const renderSyncTitle = () => {
        const content = (
          <p className="sync-title">
            <Syncz /> Syncing
          </p>
        );
    
        if (syncingStores?.length === 0 && !isOpen) {
          return (
            <Tooltip title={<>No stores are currently<br />being synchronized.</>}>
              {content}
            </Tooltip>
          );
        }
    
        return content;
    };

    return (
        <div className={`sidebar-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <div className='shopify-wrapper'>
                <div className='shopify-title'>
                    <IconShopify />
                    <p>SHOPIFY STORES</p>
                </div>
                <div className='shopify-button' onClick={toggleSidebar}>
                    <button className="sidebar-toggle">
                        <Arrow />
                    </button>
                </div>
            </div>
            <hr />
            <div className="sidebar-content">
                <div className="store-type-list">
                    <ul className="button-list">
                        <li>
                            <div className={selectedType === 'all' ? 'selected' : ''} onClick={() => handleTypeSelect('all')}>
                                <button 
                                    className={`store-type-button ${selectedType === 'all' ? 'selected' : ''}`}
                                >
                                    <img src={ALL} alt="All Stores" />
                                    <span>All Stores</span>
                                </button>
                            </div>
                        </li>
                        <li>
                            <div className={selectedType === 'ws' ? 'selected' : ''} onClick={() => handleTypeSelect('ws')}>
                                <button 
                                    className={`store-type-button ${selectedType === 'ws' ? 'selected' : ''}`}
                                >
                                    <img src={WS} alt="WebSystem" />
                                    <span>WebSystem</span>
                                </button>
                            </div>
                        </li>
                        <li>
                            <div className={selectedType === 'kb' ? 'selected' : ''} onClick={() => handleTypeSelect('kb')}>
                                <button 
                                    className={`store-type-button ${selectedType === 'kb' ? 'selected' : ''}`}
                                >
                                    <img src={KB} alt="Kiosk & Brochure" />
                                    <span>Kiosk & Brochure</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
                <hr />
                {renderSyncTitle()}
                <StoresListSyncWidget stores={syncingStores} isLoading={isLoading} />
            </div>
        </div>
    );
}