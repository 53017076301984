import { Store } from "../../../types"
import { useState, useEffect } from "react"
import StoreReportProductList from "./products/StoreReportProductList"
import StoreReportCategoriesList from "./categories/StoreReportCategoriesList"
import StoreReportProceedMessage from "./StoreReportProceedMessage"
import StoreReportBrandsList from "./brands/StoreReportBrandsList"
import StoreReportRangesList from "./ranges/StoreReportRangesList"
import StoreReportProductCategoryList from "./productCategories/StoreReportProductCategoryList"

type Props =
    {
        store: Store
    }

export default function StoreReportMenu({ store }: Props) {
    const [activeTab, setActiveTab] = useState('products');
    const [proceed, setProceed] = useState(false);

    const getContent: (type: string) => void = (type: string) => {
        setActiveTab(type);
    };

    useEffect(() => {
        updateContent();
    }, []);

    const updateContent: () => void = () => {

    }

    return (
        <div className="div-tabs reportTable">
            <div className="tabs">
                <button className={`button ${activeTab === 'products' ? 'active' : 'inactive'}`} onClick={() => getContent("products")}>Products</button>
                <button className={`button ${activeTab === 'categories' ? 'active' : 'inactive'}`} onClick={() => getContent("categories")}>Categories</button>
                <button className={`button ${activeTab === 'brands' ? 'active' : 'inactive'}`} onClick={() => getContent("brands")}>Brands</button>
                <button className={`button ${activeTab === 'ranges' ? 'active' : 'inactive'}`} onClick={() => getContent("ranges")}>Product Ranges</button>
                <button className={`button ${activeTab === 'variants' ? 'active' : 'inactive'}`} onClick={() => getContent("variants")}>Product Variants</button>
                <button className={`button ${activeTab === 'product-categories' ? 'active' : 'inactive'}`} onClick={() => getContent("product-categories")}>Products Categories</button>
            </div>

            <div className="div-content">
                <div aria-disabled={activeTab === 'products'} className={`tab-content ${activeTab === 'products' ? 'active' : 'disabled'}`}>
                    {!proceed ?
                        <StoreReportProceedMessage setProceed={setProceed}></StoreReportProceedMessage>
                        :
                        <StoreReportProductList store={store}></StoreReportProductList>
                    }

                </div>
                <div aria-disabled={activeTab === 'categories'} className={`tab-content ${activeTab === 'categories' ? 'active' : 'disabled'}`}>
                    <StoreReportCategoriesList store={store}></StoreReportCategoriesList>
                </div>
                <div aria-disabled={activeTab === 'brands'} className={`tab-content ${activeTab === 'brands' ? 'active' : 'disabled'}`}>
                    <StoreReportBrandsList store={store}></StoreReportBrandsList>
                </div>
                <div aria-disabled={activeTab === 'ranges'} className={`tab-content ${activeTab === 'ranges' ? 'active' : 'disabled'}`}>
                    <StoreReportRangesList store={store}></StoreReportRangesList>
                </div>
                <div aria-disabled={activeTab === 'variants'} className={`tab-content ${activeTab === 'variants' ? 'active' : 'disabled'}`}>
                    <p className="comingSoon">Coming soon...</p>
                </div>
                <div aria-disabled={activeTab === 'product-categories'} className={`tab-content ${activeTab === 'product-categories' ? 'active' : 'disabled'}`}>
                    <StoreReportProductCategoryList store={store}></StoreReportProductCategoryList>
                </div>
            </div>
        </div>
    )
}