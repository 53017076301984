import Divider from '@mui/material/Divider';
import RSlogo from '../../../../../src/assets/RSlogo.png';
import { NewStoreInfo } from '../../../../types';
import { useState, useEffect } from "react";
import { createNewStore } from '../../../../lib/admin';
import Close from '../../../../assets/Close';
import { SpinnerCircularFixed } from '../../../../assets/spinner/SpinnerCircularFixed';

export default function StoreCreationForm() {
    const [newStore, setNewStore] = useState<undefined | NewStoreInfo>(undefined);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [completeStore, setCompleteStore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const attributeNames = ["Id", "Friendly Name", "RS Account Guid", "RS Account Id", "Site Domain", "Shopify Store URL"];
    const planOptions = [
        { value: '1', label: 'Bronze' },
        { value: '2', label: 'Silver' },
        { value: '3', label: 'Gold' },
        { value: '4', label: 'Platinum' },
        { value: '5', label: 'Kiosk' },
        { value: '6', label: 'Brochure' }
    ];

    const handleSaveChanges = () => {
        setLoading(true);
        const updatedNewStore: NewStoreInfo = {
            id: Number((document.getElementById('Id') as HTMLInputElement).value),
            friendlyName: (document.getElementById('Friendly Name') as HTMLInputElement).value,
            accountGuid: (document.getElementById('RS Account Guid') as HTMLInputElement).value,
            accountId: Number((document.getElementById('RS Account Id') as HTMLInputElement).value) || 0,
            dns: (document.getElementById('Site Domain') as HTMLInputElement).value,
            syncApiKey: (document.getElementById('sync Client Key') as HTMLInputElement).value,
            syncApiSecret: (document.getElementById('sync Client Secret') as HTMLInputElement).value,
            storeUrl: (document.getElementById('Shopify Store URL') as HTMLInputElement).value,
            isMultiStore: (document.getElementById('Is BigFurnitureData Store') as HTMLInputElement).checked,
            enabled: false,
            hydrogenApiKey: (document.getElementById('Hydrogen Api Key') as HTMLInputElement).value,
            hydrogenApiSecret: (document.getElementById('Hydrogen Secret Key') as HTMLInputElement).value,
            storePlan: parseInt((document.getElementById('Store Plan') as HTMLSelectElement).value, 10),
            logo: (document.getElementById('Logo') as HTMLInputElement)?.value,
        };

        if (Object.entries(updatedNewStore).some(([key, value]) => {
            return value === '' && !['logo', 'dns', 'storeUrl'].includes(key);
        })) {
            setActionMessage("Please fill in all the required fields.");
            setError(true);
            setSuccess(false);
            setLoading(false);
        } else if (Number.isNaN(updatedNewStore.id)) {
            setActionMessage("Id field must be a valid number.");
            setError(true);
            setSuccess(false);
            setLoading(false);
        } else {
            setNewStore(updatedNewStore);
            setCompleteStore(true);
        }
    };

    const handleCancel = () => {
        const inputElements = document.querySelectorAll('input[type="text"]') as NodeListOf<HTMLInputElement>;
        inputElements.forEach((element) => {
            element.value = '';
        });
        const checkbox = document.getElementById('Is BigFurnitureData Store') as HTMLInputElement;
        if (checkbox) {
            checkbox.checked = false;
        }
        const select = document.getElementById('Store Plan') as HTMLSelectElement;
        if (select) {
            select.value = '0';
        }
        setError(false);
        setSuccess(false);
    };

    const createStore: () => Promise<void> = async () => {
        if (newStore == undefined) return;
        const response = await createNewStore(newStore);
        if (response.status == 200) {
            setActionMessage("Store created successfully. Please complete any missing information and run the necessary mutations");
            setError(false);
            setSuccess(true);
        } else {
            setActionMessage("Something went wrong. Please try again later or review the information you entered");
            setError(true);
            setSuccess(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (completeStore) {
            createStore();
            setCompleteStore(false);
        }
    }, [completeStore, newStore]);

    return (
        <div className="sync-management newStore">
            <h5 className='shopifyName'>Shopify Stores</h5>
            <div className="sync-header">
                <img className="Rslogo" src={RSlogo} alt="logo" />
                <h1> Add new store</h1>
            </div>
            <Divider className="divider" />
            <div className="form-container">
                <div className="column">
                    {attributeNames.map((attributeName, index) => (
                        <div className="field" key={index}>
                            <p className="field-label">{attributeName}</p>
                            <div className="field-edit-a">
                                <input
                                    type="text"
                                    id={attributeName}
                                    className={
                                        error && attributeName !== 'Id' && (document.getElementById(attributeName) as HTMLInputElement).value === '' ? 'input-error' : ''
                                    }
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="column">
                    <div className="field">
                        <p className="field-label">Logo</p>
                        <div className="field-edit">
                            <input
                                type="text"
                                id="Logo"
                            />
                        </div>
                    </div>
                    <div className="field">
                        <p className="field-label">Store Plan</p>
                        <div className="field-edit">
                            <select id="Store Plan">
                                {planOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="field bfdStore">
                        <p className="field-label">Is BigFurnitureData Store</p>
                        <div className="field-edit">
                            <input
                                type="checkbox"
                                id="Is BigFurnitureData Store"
                            />
                        </div>
                    </div>  
                </div>                      
                <hr></hr>
                <div className="create-container-wrapper">
                    <div className="">
                        <p className="title-create-container">Shopify Sync App</p>
                        <div className="create-container">

                            <div className="create-container-item">
                                <p className="field-label">Application Client Key</p>
                                <div className="field-edit">
                                    <input
                                        type="text"
                                        id="sync Client Key"
                                        className={error && (document.getElementById("sync Client Key") as HTMLInputElement).value === '' ? 'input-error' : ''}
                                    />
                                </div>
                            </div>

                            <div className="create-container-item">
                                <p className="field-label">Application Client Secret</p>
                                <div className="field-edit">
                                    <input
                                        type="text"
                                        id="sync Client Secret"
                                        className={error && (document.getElementById("sync Client Secret") as HTMLInputElement).value === '' ? 'input-error' : ''}

                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
<hr></hr>
                <div className="create-container-wrapper">
                    <div className="">
                   
                        <p className="title-create-container">Shopify Hydrogen App</p>
                        <div className="create-container">

                            <div className="create-container-item">
                                <p className="field-label">Application Client Key</p>
                                <div className="field-edit">
                                    <input
                                        type="text"
                                        id="Hydrogen Api Key"
                                        className={error && (document.getElementById("Hydrogen Api Key") as HTMLInputElement).value === '' ? 'input-error' : ''}
                                    />
                                </div>
                            </div>
                            <div className="create-container-item">
                                <p className="field-label">Application Client Secret</p>
                                <div className="field-edit">
                                    <input
                                        type="text"
                                        id="Hydrogen Secret Key"
                                        className={error && (document.getElementById("Hydrogen Secret Key") as HTMLInputElement).value === '' ? 'input-error' : ''}
                                    />
                                </div>
                            </div>
                        </div>
                   
                </div>
                </div>
            </div>
            <div className="footer">
                <div className="button-container">
                    <button className="edit-button cancelBtn" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className="edit-button save" onClick={handleSaveChanges}>
                        {loading ? <SpinnerCircularFixed size={15} /> : 'Save changes'}
                    </button>
                </div>
                <div className="error-container">
                    {error ? (
                        <span className="error-message">{actionMessage}</span>
                    ) : success ? (
                        <span className="success-message">{actionMessage}</span>
                    ) : null}
                </div>
            </div>
        </div>
    );
}