import React, { useState, useEffect } from "react";
import { Store } from "../../../types";
import { Divider } from "@mui/material";
import Editor from "@monaco-editor/react";
import { getStoreJSONSettings, putStoreJSONSettings } from "../../../lib/admin";
import { SpinnerCircularFixed } from "../../../assets/spinner/SpinnerCircularFixed";
import InfoWarningIcon from "../../../assets/InfoWarningIcon";

type Props = {
    store: Store,
    hasUnsavedChanges: boolean,
    setHasUnsavedChanges: (flag: boolean) => void,
    hasBeenUpdated: boolean,
    setHasBeenUpdated: (flag: boolean) => void
}

export default function StoreRetailSystemForm({store, hasUnsavedChanges, setHasUnsavedChanges, hasBeenUpdated, setHasBeenUpdated}: Props) {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("");
    const [jsonContent, setJsonContent] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        setIsLoading(true);
        try {
            const response = await getStoreJSONSettings(store.id.toString());
            if (response.status === 204 || response.data === null) {
                setJsonContent("{}");
            } else {
                setJsonContent(JSON.stringify(response.data, null, 2));
            }
            setError(false);
        } catch (err) {
            setError(true);
            setActionMessage('Failed to load settings. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditorChange = (value: string | undefined) => {
        if (value !== undefined) {
            setJsonContent(value);
            setHasUnsavedChanges(true);
        }
    };

    const handleSave = async () => {
        setIsLoading(true);
        setError(false);
        setSuccess(false);
        try {
            const response = await putStoreJSONSettings(store.id.toString(), jsonContent)
            if (!response.ok) {
                throw new Error("Failed to update settings");
            }
            setSuccess(true);
            setActionMessage("Settings updated successfully");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } catch (err) {
            setError(true);
            setActionMessage("Failed to update settings. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        fetchSettings();
        setHasUnsavedChanges(false);
    };

    if (isLoading) {
        return (
            <div className="spinner-div spinnerReport">
                <SpinnerCircularFixed className='' size={35} thickness={140} speed={120} color="rgba(203, 216, 234, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="sync-management">
                <div className="error-container">
                    <span className="error-message">{actionMessage}</span>
                </div>
            </div>
        );
    }

    return (
        <div className="sync-management">
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1>Store Settings JSON</h1>
            </div>
            <Divider className="divider"/>
            <div className="form-container">
                <div className="warning-box">
                    <InfoWarningIcon></InfoWarningIcon>
                    <div className="content">
                        <p><strong>Important:</strong> The store settings feature allows you to modify various store behaviors without the need to deploy your store again.</p>
                        <p>The GET request is made directly from Shopify.</p>
                        <p>The POST is done in two steps:</p>
                        <ol>
                            <li>First, it saves directly to Shopify.</li>
                            <li>Then, it saves to the database because the sync has some logic that changes according to the settings.</li>
                        </ol>
                        <p>More info: <a href="https://retailsystem-dev.notion.site/Store-Settings-DB-70d06c4041514a429cf591af3161b69a" target="_blank" rel="noopener noreferrer">Store Settings | Notion</a></p>
                    </div>
                </div>
                <div className="store-settings-text">
                    <Editor
                        height="100%"
                        defaultLanguage="json"
                        value={jsonContent}
                        onChange={handleEditorChange}
                        theme="vs-dark"
                        options={{
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            fontSize: 14,
                            tabSize: 2,
                            wordWrap: "on",
                            wrappingIndent: "same",
                            automaticLayout: true,
                            useShadowDOM: false,
                        }}
                    />
                </div>
            </div>
            <div className="footer">
                <div className="error-container">
                    {error ? (
                        <span className="error-message">{actionMessage}</span>
                    ) : success ? (
                        <span className="success-message">{actionMessage}</span>
                    ) : null}
                </div>
                <div className="button-container">
                    <button className="edit-button" onClick={handleCancel} disabled={!hasUnsavedChanges}>
                        Cancel
                    </button>
                    <button
                        className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                        disabled={!hasUnsavedChanges}
                        onClick={handleSave}
                    >
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    );
}