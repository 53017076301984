
import { useEffect, useState } from "react";
import { Store, StoreDifferences } from '../../../types';
import StoreCardHeader from './StoreCardHeader';
import StoreCardSyncArea from "./StoreCardSyncArea";
import StoreCardDifferences from "./StoreCardDifferences";

type Props =
{
    store: Store,
    sync: (store: Store) => any,
    productDifferences: StoreDifferences | null,
    collectionDifferences: StoreDifferences | null
}

export default function StoreCardContainer({ store, sync, productDifferences,  collectionDifferences}: Props) 
{
    const [error, setError] = useState<undefined | string>();
    const [syncState, setSyncState] = useState<number>(store.syncState);


    const syncStore : () => Promise<void> = async () => 
    {
        const error = await sync(store);
        if(error === "") 
        {
            setSyncState(1);
            setError("");
        }
        else setError(error);
    }
    
    return (
        <div className="store-date">
            <div className="info-stores-container-top">
                <StoreCardHeader store={store} key={store.id}></StoreCardHeader>
                <StoreCardSyncArea store={store} error={error} syncState={syncState} syncStore={syncStore}></StoreCardSyncArea>
                <StoreCardDifferences store={store} productDifferences={productDifferences} collectionDifferences={collectionDifferences}></StoreCardDifferences>
            </div>
        </div>
    )
}