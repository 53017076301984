import { useEffect, useState } from "react";
import { Store } from "../../../types";
import { updateStoreSyncType } from "../../../lib/admin";
import { Divider, Tooltip } from "@mui/material";
import SyncType from "../../../assets/SyncType";
import InfoWarningIcon from "../../../assets/InfoWarningIcon";
import TriangularWarningIcon from "../../../assets/TriangularWarningIcon";

type Props =
{
    store: Store, 
    hasUnsavedChanges: boolean,
    setHasUnsavedChanges: (flag: boolean) => void,
    hasBeenUpdated: boolean,
    setHasBeenUpdated: (flag: boolean) => void
}

export default function StoreSyncFormType ({ store, hasUnsavedChanges, hasBeenUpdated, setHasUnsavedChanges, setHasBeenUpdated }: Props) 
{
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [actionMessage, setActionMessage] = useState("An error occurred. Please try again later.");
    const [syncType, setSyncType] = useState(store.developmentPhase);

    const handleUpdateType = async () => {
        const newType = syncType;
        try {
            await updateStoreSyncType(store.id, newType);
            setSyncType(newType);
            store.developmentPhase = newType;
            setSuccess(true);
            setError(false);
            setActionMessage("Changes saved successfully.");
            setHasUnsavedChanges(false);
            setHasBeenUpdated(true);
        } catch (error) {
            setSuccess(false);
            setError(true);
            setActionMessage("An error occurred. Please try again later.");
        }
    };

    const handleCancelChanges = () => {
        setSyncType(store.developmentPhase)
        setHasUnsavedChanges(false);
        setError(false);
        setSuccess(false);
    };

    const handleFieldChange = () => {
        setHasUnsavedChanges(true);
    };

    return(
        <div className="sync-management">
            <div className="sync-header">
                <p>Shopify Stores/{store.friendlyName}</p>
                <h1><SyncType /> Sync Type</h1>
            </div>
            <Divider className="divider"/>
            <div className="form-container">
                <div className="warning-box">
                    <InfoWarningIcon></InfoWarningIcon>
                    <div className="content">
                        <p><strong>Important:</strong> This setting affects how synchronizer will work for this account from now on.</p>
                        <p>We will gradually migrate all V1 features to V2, but for the moment it is all a work in progress, so it is possible that some bugs may appear during this process.</p>
                        <p>If you are not sure, please contact the development team.</p>
                    </div>
                </div>
                <div className="synctype-container">
                    <button className={`regular ${syncType == 1 ? 'active' : ''}`} onClick={()=>{setSyncType(1); handleFieldChange();}}>Legacy</button>
                    <button className={`v2 ${syncType == 2 ? 'active' : ''}`} onClick={()=>{setSyncType(2); handleFieldChange();}}>V2</button>
                    <button className={`v2-beta ${syncType == 3 ? 'active' : ''}`} onClick={()=>{setSyncType(3); handleFieldChange();}}>V2 Beta</button>
                </div>
                {!syncType && (
                    <div className="synctype-about">
                        <p><strong>This store has no sync type set. Select a sync type to view its characteristics.</strong></p>
                    </div>
                )}
                {syncType != null && (
                    <div className="synctype-about">
                        <p className='title'><strong>About this option:</strong></p>
                        {
                            syncType == 1 && (
                                <div className="about-content">
                                    <p><strong>Legacy (V1)</strong></p>
                                    <p>The original version, all features are working, but nothing new is added.</p>
                                    <p>This version is not maintained anymore, some small fixes only if the feature is not available in V2.</p>
                                </div>
                            )
                        }
                        {
                            syncType == 2 && (
                                <div className="about-content">
                                    <p><strong>V2</strong></p>
                                    <p>This version contains only some features, like products and products/categories synchronization.</p>
                                    <p>Everything else will still be running in Legacy version, until it is developed here. All new developments will be first tested in Beta phase.</p>
                                </div>
                            )
                        }
                        {
                            syncType == 3 && (
                                <div className="about-content">
                                    <p><strong>V2 Beta</strong></p>
                                    <div className="about-warning-box">
                                        <TriangularWarningIcon></TriangularWarningIcon>
                                        <div className="content">
                                            <p><strong>Warning: </strong>This is an unstable version, made only for testing new features in V2.</p>
                                            <p>Consider using only in development stores, or if you really need a new feature only existent in this phase.</p>
                                            <p>When the feature is correctly tested, will be moved to V2 and new features will be added to this phase.</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )}
            </div>
            <Divider></Divider>
            <div className="footer">
                <div className="error-container">
                {error ? (
                    <span className="error-message">{actionMessage}</span>
                ) : success ? (
                    <span className="success-message">{actionMessage}</span>
                ) : null}
                </div>
                <div className="button-container">
                    <div>
                        <button className="edit-button" onClick={()=>handleCancelChanges()}>
                            Cancel
                        </button>
                        <button
                            className={`edit-button save ${!hasUnsavedChanges ? 'disabled' : ''}`}
                            onClick={handleUpdateType}
                            disabled={!hasUnsavedChanges}
                            >
                            Save changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}