import { Store, StoreDifferences } from "../../../types"
import StoreCard from "../card/StoreCardContainer"
import StoreCardLoading from "../card/StoreCardLoading"
import { useEffect, useState } from "react";
import StoreItemContainer from "../item/StoreItemContainer";
import StoresListItemMenu from "./StoreListItemMenu";

type StoreListProps =
    {
        stores: undefined | Store[]
        sync: (store: Store) => any
        isLoading: boolean
        viewList: boolean
    }

export default function StoresList({ stores, sync, isLoading, viewList }: StoreListProps) {

    if (!isLoading && (!stores || stores.length === 0)) {
        return (
            <div className='store-section-list'>
                <p className="no-results">No results found</p>
            </div>
        );
    }

    return (
        <div className={viewList ? 'store-section-list' : 'row store-sections-cards'}>
            {!viewList ? (
                isLoading && [...Array(12)].map((_, i) => (
                <div key={i} className="cardContainer">
                    <StoreCardLoading></StoreCardLoading>
                </div>
                ))
            ) : (
                <StoresListItemMenu></StoresListItemMenu>
            )}
            {stores !== undefined && stores.length > 0 && !isLoading &&
                stores.map((store, i) => {
                    const storeReport = store.storeReport ? JSON.parse(store.storeReport) : null;

                    const storeProductDifferences: StoreDifferences = {
                        indexCount: storeReport?.Products.IndexCount || 0,
                        shopifyCount: storeReport?.Products.ShopifyCount || 0,
                        mirrorCount: storeReport?.Products.MirrorCount || 0,
                        differences: storeReport?.Products.Differences || [],
                    };

                    const storeCollectionDifferences: StoreDifferences = {
                        indexCount: storeReport?.Collections.IndexCount || 0,
                        shopifyCount: storeReport?.Collections.ShopifyCount || 0,
                        mirrorCount: storeReport?.Collections.MirrorCount || 0,
                        differences: storeReport?.Collections.Differences || [],
                    };

                    return (
                        <div key={i} className={`${viewList ? 'list-view-item' : 'cardContainer '}`}>
                            {viewList ? (
                                <StoreItemContainer store={store} sync={sync}></StoreItemContainer>
                            ) : (
                                <StoreCard 
                                    store={store}
                                    sync={sync}
                                    productDifferences={storeProductDifferences}
                                    collectionDifferences={storeCollectionDifferences}
                                ></StoreCard>
                            )}
                        </div>
                    );
                })
            }
        </div>
    );
}


