import { useEffect, useState } from "react";

type Props =
  {
    setClearFilters: (value: boolean) => void
    setSearch: (value: string) => void
    setProductSearch: (value: boolean) => void
    setCategorySearch: (value: boolean) => void
  }

export default function StoreReportProductCategoryFilterMenu({setClearFilters, setSearch, setProductSearch, setCategorySearch }: Props) {
    const [search, setNewSearch] = useState<string>("");
    const [checkedProductSearch, setCheckedProductSearch] = useState<boolean>(true);
    const [checkedCategorySearch, setCheckedCategorySearch] = useState<boolean>(false);

    const handleProductSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setCheckedCategorySearch(!event.target.checked);
        setCheckedProductSearch(event.target.checked);
        setProductSearch(event.target.checked);
    };

    const handleCategorySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setCheckedProductSearch(!event.target.checked);
        setCheckedCategorySearch(event.target.checked);
        setCategorySearch(event.target.checked);
    };

    const handleClearFilters = () => {
        setClearFilters(true);
        setNewSearch("");
    }

    const handleSearch = () => {
        setSearch(search);
    };

    return (
        <div className="filterBar productFilters">
            <div className="slider-menu" style={{ position: 'relative' }}>
                <div className="menu-button-div">
                    <div className="left-button">
                        <div className="right-button">
                            <button className="filter-button cBtn" onClick={handleClearFilters}>
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-input-products">
                <div className="search-checkbox">
                    <p className="search-check-title">Search by: </p>                   
                    <input type="checkbox" id="product-search-check" checked={checkedProductSearch} onChange={handleProductSearch}/>
                    <p className="search-check-sub">Product</p>                  
                    <input type="checkbox" id="category-search-check" checked={checkedCategorySearch} onChange={handleCategorySearch}/>
                    <p className="search-check-sub">Category</p>
                </div>
                <input 
                    type="text"
                    placeholder="Search..."
                    id="product-search"
                    value={search}
                    onChange={(e) => setNewSearch(e.target.value)}
                />
                <button type="button" id="search-button" onClick={handleSearch}>
                    <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/descarga.png?v=1699470094" alt="" />
                </button>
            </div>
        </div>
    )
}
    