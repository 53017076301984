const convertUtcToLocal = (utcDate: string): string => {
  const utcDateTime = Date.parse(utcDate);
  const localDateTime = new Date(utcDateTime - (new Date().getTimezoneOffset() * 60 * 1000));

  let localDateString = localDateTime.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const [datePart, timePart] = localDateString.split(', ');
  const timeSections = timePart.split(':');
  const timeString = (timeSections[0] === '24' ? '00' : timeSections[0]) + ":" + timeSections[1] + ":" + timeSections[2];

  const updatedDateString = `${datePart}, ${timeString}`;
  return updatedDateString;
};

export default convertUtcToLocal;

