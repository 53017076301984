import * as React from 'react';
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import {login} from '../../lib/admin'
import { useNavigate } from "react-router-dom";
import { User } from '../../types';

export default function LoginForm() {
    const [user, setUser] = useState<undefined | User>(undefined);
    const [loginFailed, setLoginFail] = useState(false);
    const navigate = useNavigate();
    const [isMounted, setIsMounted] = useState(true);

    useEffect(() => {
      return () => {
          setIsMounted(false);
      };
    }, []);

    const onSuccess = async (response: any) => {
      if(!isMounted) return;
      const {
        credential,
      } = response;
      try {
          const backendResponse = await login(credential);

          if (backendResponse.email !== undefined) {

            const user: User = {
              email: backendResponse.email,
              role: backendResponse.role,
              token: credential,
              localTimezone: backendResponse.localTimezone == true ? 'active' : 'inactive',
            };

            setUser(user);
            localStorage.setItem('email', user.email);
            localStorage.setItem('role', user.role);
            localStorage.setItem('token', user.token);
            localStorage.setItem('time-zone', user.localTimezone);
            navigate('/stores', { replace: true });
          } else {
            console.error('Google login failed:', backendResponse);
            setLoginFail(true);
          }
      } catch (err) {
        console.error('Google login failed:', err);
        setLoginFail(true);
      }
    };
  
    return (
      <div className="login">
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box className="login-container">
                <Avatar className="avatar">
                    <LockOutlinedIcon />
                </Avatar>
                <GoogleLogin
                    onSuccess={credentialResponse => {
                      onSuccess(credentialResponse);
                    }}
                    onError={() => {
                      setLoginFail(true);
                    }}
                />
                {loginFailed && (
                  <span className="error-message">Login failed. Please try again.</span>
                )}
            </Box>
        </Container>
      </div>
    );
}