import { StoreShopifySyncInfo, StoreShopifyInfo, StoreSyncInfo, StoreRSInfo, NewStoreInfo, StoreCSVInfo, SyncFilters } from "../types";
import accountsData from './accounts.json';

const urlStore = process.env.REACT_APP_API_NODE_URL + "stores";
const urlReport = process.env.REACT_APP_API_BASE_URL + "product/report/";
const urlCategories = process.env.REACT_APP_API_BASE_URL + "categories/report/";
const urlRanges = process.env.REACT_APP_API_BASE_URL + "productrange/report/";
const urlProductCategories = process.env.REACT_APP_API_BASE_URL + "productcategories/report/";
const urlBrands = process.env.REACT_APP_API_BASE_URL + "brand/report/";
const urlSignIn = process.env.REACT_APP_API_NODE_URL + "auth/signin";
const urlSync = process.env.REACT_APP_API_BASE_URL + "store";
const urlTimezone = process.env.REACT_APP_API_NODE_URL + "auth/timezone";
const urlGetJSONSettings = process.env.REACT_APP_API_BASE_URL + "ws/Customization/GetMetafield/Settings"
const urlPutJSONSettings = process.env.REACT_APP_API_BASE_URL + "ws/Customization/UpsertMetafield/Settings"

export const getAllStores = async () => 
{
    const res =  await fetch(urlStore, {
        method: 'GET',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
    }).then(function(response) 
    {
        return response.json();
    }).then(function(data) 
    {
       return data;
    });
    return res;
}

export const getAllProducts = async (storeId: number) => 
{
    const res = await fetch(`${urlReport}${storeId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const data = await res.json();

    if (data.productReportResult.length != 0) 
        return data.productReportResult;
    else
        return null;
}

export const getAllCategories = async (storeId: number) => 
{
    const res = await fetch(`${urlCategories}${storeId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const data = await res.json();

    if (data.categoryReportResult.length != 0) 
        return data.categoryReportResult;
    else
        return null;
}

export const getAllBrands = async (storeId: number) => 
{
    const res = await fetch(`${urlBrands}${storeId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const data = await res.json();

    if (data.brandReportResults.length != 0) 
        return data.brandReportResults;
    else
        return null;
}

export const getAllProductRanges = async (storeId: number) => 
{
    const res = await fetch(`${urlRanges}${storeId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const data = await res.json();
    if (data.productRangeReportResults.length != 0) 
        return data.productRangeReportResults;
    else
        return null;
}

export const getAllProductCategories = async (storeId: number) => 
{
    const res = await fetch(`${urlProductCategories}${storeId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const data = await res.json();
    if (data.productCategoriesReportResults.length != 0) 
        return data.productCategoriesReportResults;
    else
        return null;
}

export const getStoreById = async (storeId: number) => 
{
    const res = await fetch(`${urlStore}/${storeId}`, {
        method: 'GET',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
    }).then(function(response) 
    {
        return response.json();
    }).then(function(data) 
    {
       return data;
    });
    return res;
}

export const enableStore = async (storeId: number, enabled: boolean) => 
{
    const body = JSON.stringify({ enabled });
    const res =  await fetch(`${urlStore}/update/enable/${storeId}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body
    }).then(function(response) 
    {
        return response.json();
    }).then(function(data) 
    {
       return data;
    });
    return res;
}

export const syncCall = async (storeId: number) => 
{
    const query = localStorage.getItem('email');
    const res =  await fetch(`${urlSync}/SyncStore/${storeId}?email=${query}`, {
        method: 'POST',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
    })
    if(res.status == 200) return "";
    const msg = res.text();
    return msg;
}

export const getStatus = async (storeId: number) => 
{
    const res =  await fetch(`${urlSync}/status/${storeId}`, {
        method: 'GET',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
    }).then(function(response) 
    {
        return response.json();
    }).then(function(data) 
    {
       return data;
    });
    return res;
}

export const login = async (token: any) => 
{
    const res = await fetch(urlSignIn, {
        method: 'PUT',
        headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
        }
    });

    const data = await res.json();
    return data;
}

export const updateStoreSyncType = async (id: number, developmentPhase: number) => {
    const body = JSON.stringify({ developmentPhase });
    const response = await fetch(`${urlStore}/update/sync/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });

    if (!response.ok) {
        throw new Error(`Failed to edit store sync: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const editStoreSync = async (storeSyncInfo: StoreSyncInfo) => 
{
    const body = JSON.stringify(storeSyncInfo);
    
    const response = await fetch(`${urlStore}/sync/${storeSyncInfo.id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });

    if (!response.ok) {
        throw new Error(`Failed to edit store sync: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const editStoreRSInfo = async (updatedStore: StoreRSInfo) => 
{
    const body = JSON.stringify(updatedStore);
    const response = await fetch(`${urlStore}/update/${updatedStore.id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });
    if (!response.ok) {
        throw new Error(`Failed to edit store information: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const editStoreCSVInfo = async (updatedStore: StoreCSVInfo) =>
{
    const body = JSON.stringify(updatedStore);
    const response = await fetch(`${urlStore}/update/csv/${updatedStore.id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });
    if (!response.ok) {
        throw new Error(`Failed to edit store information: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const getCSVAccountData = async () =>
{
    try {
      return accountsData;
    } catch (error) {
      console.error('Error fetching JSON data:', error);
      return [];
    }
}

export const editShopifyHydrogenInfo = async (updatedStore: StoreShopifySyncInfo) => 
{
    const body = JSON.stringify(updatedStore);
    const response = await fetch(`${urlStore}/update/shopifyHydrogen/${updatedStore.id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });
    if (!response.ok) {
        throw new Error(`Failed to edit store information: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const editStoreShopify = async (storeShopifyInfo:  StoreShopifyInfo) => 
{
    const body = JSON.stringify(storeShopifyInfo);
    
    const response = await fetch(`${urlStore}/update/shopify/${storeShopifyInfo.id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });

    if (!response.ok) {
        throw new Error(`Failed to edit store sync: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const editShopifySyncInfo = async (updatedStore: StoreShopifySyncInfo) => 
{
    const body = JSON.stringify(updatedStore);
    const response = await fetch(`${urlStore}/update/shopifySync/${updatedStore.id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });
    if (!response.ok) {
        throw new Error(`Failed to edit store information: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const setNewTimezonePreference = async(preference: boolean) =>
{
    const body = JSON.stringify({preference});
    const response = await fetch(`${urlTimezone}`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });
    if (!response.ok) {
        throw new Error(`Failed to edit store information: ${response.statusText}`);
    }
    const data = await response.json();
    if (!data) {
        console.error('Received empty or invalid JSON response from the server.');
        throw new Error('Invalid server response.');
    }
    return data;
}

export const createNewStore = async (newStore: NewStoreInfo) => 
{
    const updatedNewStore = Object.fromEntries(
        Object.entries(newStore).map(([key, value]) => [key, value === '' ? null : value])
    );
    const body = JSON.stringify(updatedNewStore);
    const response = await fetch(`${urlStore}/create`, {
        method: 'PUT',
        headers: {
            'Authorization': `${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body,
    });
    return response;
}

export const syncStoreWithFilters = async (filters: SyncFilters, storeID: number) => 
{
    const options = [];

    if (filters.navigation) 
        options.push("-n");
    if (filters.products)
        options.push("-p");
    if(filters.variants)
        options.push("-v");
    if(filters.brands)
        options.push("-b");
    if(filters.ranges)
        options.push("-r");
    if(filters.categories)
        options.push("-c");
    if(filters.collections)
        options.push("-o");
    if(filters.metafields)
        options.push("-z");
    if(filters.parser)
        options.push("-y");

    const uri = "&args=" + options.join("%20");
    const query = localStorage.getItem('email');
    const response = await fetch(`${urlSync}/syncstore/${storeID}?email=${query}${uri}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return response;
}

export const getStoreJSONSettings = async (storeId: string) => {
    try {
        const response = await fetch(urlGetJSONSettings, {
            method: 'GET',
            headers: {
                'WS-API-KEY': `${process.env.REACT_APP_WS_API_KEY}`,
                'WS-STORE-ID': storeId,
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 204) {
            return { status: 204, data: null };
        }

        if (!response.ok) {
            throw new Error('Failed to fetch settings');
        }

        const data = await response.json();
        return { status: response.status, data };
    } catch (error) {
        throw new Error('Failed to fetch settings');
    }
};

export const putStoreJSONSettings = async (storeId: string, json: string) => {
    try {
        const body = json;
        const response = await fetch(urlPutJSONSettings, {
            method: 'POST',
            headers: {
                'WS-API-KEY': `${process.env.REACT_APP_WS_API_KEY}`,
                'WS-STORE-ID': storeId,
                'Content-Type': 'application/json'
            },
            body
        });

        if (!response.ok) {
            throw new Error('Failed to update settings');
        }

        return response;
    } catch (error) {
        throw new Error('Failed to update settings');
    }
};