import { useEffect, useState } from "react";
import Filters from "../../../../assets/Filters";
import Arrow from "../../../../assets/Arrow";
import Close from "../../../../assets/Close";
import { Search } from "@mui/icons-material";

type Props =
  {
    setInShopify: (value: boolean) => void
    setInMirror: (value: boolean) => void
    setOnIndex: (value: boolean) => void
    setNotInShopify: (value: boolean) => void
    setNotInMirror: (value: boolean) => void
    setNotOnIndex: (value: boolean) => void
    setOrderBy: (value: string) => void
    setClearFilters: (value: boolean) => void
    setSearch: (value: string) => void
  }

export default function StoreReportProductFilterMenu({ setInShopify, setInMirror, setOnIndex, setNotInShopify, setNotInMirror, setNotOnIndex, setOrderBy, setClearFilters, setSearch }: Props) {
    const [isSliderVisible, setSliderVisible] = useState(false);
    const [isInShopify, setIsInShopify] = useState<boolean>(false);
    const [isInMirror, setIsInMirror] = useState<boolean>(false);
    const [isOnIndex, setIsOnIndex] = useState<boolean>(false);
    const [isNotInShopify, setIsNotInShopify] = useState<boolean>(false);
    const [isNotInMirror, setIsNotInMirror] = useState<boolean>(false);
    const [isNotOnIndex, setIsNotOnIndex] = useState<boolean>(false);
    const [orderBy, setOrderListBy] = useState<string>("default");
    const [search, setNewSearch] = useState<string>("");

    const handleSaveChanges = () => {
        setInShopify(isInShopify);
        setInMirror(isInMirror);
        setOnIndex(isOnIndex);
        setNotInShopify(isNotInShopify);
        setNotInMirror(isNotInMirror);
        setNotOnIndex(isNotOnIndex);
    }

    const handleClearFilters = () => {
        setIsInShopify(false);
        setIsInMirror(false);
        setIsOnIndex(false);
        setIsNotInShopify(false);
        setIsNotInMirror(false);
        setIsNotOnIndex(false);
        setOrderListBy("default");
        setClearFilters(true);
        setNewSearch("");
    }

    const handleIsInShopifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setIsNotInShopify(false);
        setIsInShopify(event.target.checked);
    };

    const handleIsInMirrorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setIsNotInMirror(false);
        setIsInMirror(event.target.checked);
    };

    const handleIsOnIndexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setIsNotOnIndex(false);
        setIsOnIndex(event.target.checked);
    };

    const handleIsNotInShopifyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setIsInShopify(false);
        setIsNotInShopify(event.target.checked);
    };

    const handleIsNotInMirrorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setIsInMirror(false);
        setIsNotInMirror(event.target.checked);
    };

    const handleIsNotOnIndexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked)
            setIsOnIndex(false);
        setIsNotOnIndex(event.target.checked);
    };

    const handleOrderBy = (value: string) => {
        setOrderListBy(value);
    }

    const handleSearch = () => {
        setSearch(search);
    };

    useEffect(() => {
        setOrderBy(orderBy);
    }, [orderBy]);

    const toggleSlider = () => {
        setSliderVisible(!isSliderVisible);
    };

    const getOrderByDescription = (value: string) => {
        switch (value) {
          case "default":
            return "Default order";
          case "name-asc":
            return "A-Z";
          case "name-desc":
            return "Z-A";
          default:
            return value;
        }
    };

    return (
        <div className="filterBar productFilters">
            <div className="slider-menu" style={{ position: 'relative' }}>
                {isSliderVisible && (
                    <div className="slider-menu-overlay">
                        <div className="slider-menu lateral">
                            <button onClick={toggleSlider} className="close-button">
                                <Close></Close>
                            </button>
                            <div className="slider-parent">
                                <h1>Filters</h1>
                                <div className="filters-div">
                                    <p>Type:</p>
                                    <div className="filter-group">
                                        <label>
                                            <input type="checkbox" checked={isInShopify} onChange={handleIsInShopifyChange} /> In Shopify
                                        </label>
                                        <label>
                                            <input type="checkbox" checked={isNotInShopify} onChange={handleIsNotInShopifyChange} /> Not in Shopify
                                        </label>
                                    </div>
                                    <div className="filter-group">
                                        <label>
                                            <input type="checkbox" checked={isInMirror} onChange={handleIsInMirrorChange} /> In Mirror
                                        </label>
                                        <label>
                                            <input type="checkbox" checked={isNotInMirror} onChange={handleIsNotInMirrorChange} /> Not in Mirror
                                        </label>
                                    </div>
                                    <div className="filter-group">
                                        <label>
                                            <input type="checkbox" checked={isOnIndex} onChange={handleIsOnIndexChange} /> On Index
                                        </label>
                                        <label>
                                            <input type="checkbox" checked={isNotOnIndex} onChange={handleIsNotOnIndexChange} /> Not on Index
                                        </label>
                                    </div>
                                </div>
                                <div className="apply-button-div">
                                    <button className="ClearBtn" onClick={handleClearFilters}>
                                        Clear
                                    </button>
                                    <button className="ApplyBtn" onClick={() => { handleSaveChanges(); toggleSlider(); }}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="menu-button-div">
                    <div className="left-button">
                        <button onClick={toggleSlider} className="filter-button" type="button">
                            <Filters />
                            Filters
                        </button>
                        <div className="dropdown-wrapper">
                            <button className="filter-button" type="button" onClick={(e) => e.stopPropagation()}>
                                Order by
                                <Arrow />
                            </button>
                            <div className="dropdown-content">
                                {["default", "name-asc", "name-desc"].map((value) => (
                                <label key={value}>
                                    <input
                                    type="radio"
                                    name="orderBy"
                                    value={orderBy}
                                    checked={orderBy === value}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => {
                                        handleOrderBy(value);
                                    }}
                                    />
                                    {getOrderByDescription(value)}
                                </label>
                                ))}
                            </div>
                        </div>
                        <div className="right-button">
                            <button className="filter-button cBtn" onClick={handleClearFilters}>
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="search-input-products">
                <input 
                    type="text"
                    placeholder="Search.."
                    id="product-search"
                    value={search}
                    onChange={(e) => setNewSearch(e.target.value)}
                />
                <button type="button" id="search-button" onClick={handleSearch}>
                    <img src="https://cdn.shopify.com/s/files/1/0622/8577/5024/files/descarga.png?v=1699470094" alt="" />
                </button>
            </div>
        </div>
    )
}