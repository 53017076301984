import { Store, StoreStatus } from "../../../types"
import syncz from '../../../assets/syncz.svg';
import Arrow from "../../../assets/Arrow";
import { getStatus } from "../../../lib/admin";
import { useState } from "react";
import FilterSync from "../../../assets/FilterSync";
import StoreCardSyncModal from "./StoreCardSyncModal";


type Props =
{
    store: Store,
    error: string | undefined,
    syncState: number,
    syncStore: () => void
}

export default function StoreCardSyncArea ({store, error, syncState, syncStore}: Props) 
{
    const [status, setStatus] = useState<StoreStatus | undefined>(undefined);

    const getSync = () => 
    {
        return syncState === 0 && store.syncV2IsRunning !== 1 ? "Idle" : "Synchronizing";
    }

    const getClassSync = () => 
    {
        return syncState === 0 && store.syncV2IsRunning !== 1  ? "idle" : "synchronizing";
    }

    const updateStatus =  async () => 
    {
        const result = await getStatus(store.id);
        if (result != undefined)  
            setStatus(result)  
    }

    const [showModal, setShowModal] = useState(false);
            
    const openModal = () => {
        setShowModal(true);
    };
    
    const closeModal = () => {
      setShowModal(false);
    };
    
    return (
        <div className="bottomArea">    
            <div className="leftArea">
                <div className="two-col">
                    <p className={`title type status`}>
                        Status: 
                        <span className={`status-${getClassSync()}`}>
                            {getSync()}
                        </span>
                    </p>
                </div>
                <div className="sync-button-container">
                    <div className="basic-dates-store actions-panel syncMenuBtn">
                        <button type="button" onClick={openModal} className="btn btn-primary">
                            <FilterSync/> Sync
                        </button>
                    </div>
                    <div className="basic-dates-store actions-panel syncBtn">
                        {syncState === 1 || store.syncV2IsRunning === 1 ? 
                        <div className="div-button-synchronising">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Synchronizing
                        </div>
                        :
                        <button type="button" onClick={() => syncStore()} className="btn btn-primary">
                            <img src={syncz} alt="sync store"/> Sync All
                        </button>
                        }
                    </div>
                </div>
            </div>
            <div className="">
                <div className="row">
                    <div className="container-progress">
                    <p className="progress-title">{store.syncState == 1 || store.syncV2IsRunning === 1 ? 'Progress:' : ''}</p>
                        {store.syncState == 1 || store.syncV2IsRunning === 1 ?                         
                            <div className={"button-progress"}>
                                <button onClick={() => updateStatus()}>
                                Refresh
                                </button>
                                    {status != undefined &&
                                        <span className="data-progress">
                                            {status.progressPercent + "%"}
                                        </span>
                                    }
                            </div>
                            : <></>
                        }
                    </div>
                    
                </div>
            </div>
            <div className="more-info">
               <p className="progress-title">Quick details</p>  
               <button className = "btn-seeMore"><Arrow></Arrow></button>
            </div>
            <div className="errorArea">
                <div className={"store-error-section"}>
                    {(error !== undefined && error !== "") &&
                        <p className="store-error-message">{error}</p>
                    }
                </div>
            </div>
            <div className="modal-overlay">
                {showModal && (
                    <StoreCardSyncModal store={store} close={closeModal} syncStore={syncStore}/>
                )}
            </div>
        </div>
        )
    
}